import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatosClienteServicioService } from 'src/app/servicios/datos-cliente-servicio.service';
// ES6 Modules or TypeScript
import swal from 'sweetalert2';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})

export class InvitationComponent implements OnInit {

  token_invitation: string; // almacena el token recibido por URL
  profile_id_invitation: string // recibimos el string
  returnURL: string; //return back to URL
  name:string;
  last_name: string;
  access_token: string;
  role: string; // rol de la persona autentificada
  web_code: string;
  dataUsuarioLogeado: any;
  mostrarLogin: boolean = false;
  mostrarRegistro: boolean = false;
  mostrarRecuperarContrasena: boolean = false;
  passwordNoCoincide: boolean = false;
  envioErroneoRegistro: boolean = false;
  registradoExito: boolean = false;
  sesionIniciadaExito: boolean = false;
  envioLoginErroneo: boolean = false;
  mostrarPagina: boolean = false;
  loginRegistro: boolean = false;
  erroresFormularioRegistro: any;
  errorFormularioLogin: string;
  formRegistro: FormGroup;
  formLogin: FormGroup;
  formRecuperarContrasena: FormGroup;
  errorRecuperarContrasena: boolean = false;
  exitoRecuperarContrasena: boolean = false;
  registerError: any = {};
  loginError: any = {};
  recoveryPasswordError: any = {};
  multiLogin: boolean = false;
  profiles: any;
  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private datosClientesService: DatosClienteServicioService,
    private router: Router) {

    // Vaciamos el storage
    // IIV 07/02/24 - Recuperamos web_code y profile_id para poder volver atrás
    let web_code = localStorage.getItem('web_code');
    let profile_id = localStorage.getItem('profile_id');

    localStorage.clear();

    localStorage.setItem('web_code', web_code);
    localStorage.setItem('profile_id', profile_id);

    // recogemos el token de la URL del admin  y lo almacenamos en el local storage
    this.route
      .queryParams
      .subscribe(params => {
       // this.token_invitation = params['token'];
        this.profile_id_invitation = params['profile'];
        this.returnURL = params['returnURL'];
        if(this.returnURL){
          localStorage.setItem('returnURL', this.returnURL);
        }
      });
    // si no recibimos o un token o un id profile, los sacamos fuera
    
      if (!this.profile_id_invitation) {
      document.location.href = 'http://celebrasuvida.es/';
    }
    // creamos los dos formulario
    this.crearFormularioRegistro();
    this.crearFormularioLogin();
    this.crearFormularioRecuperarContrasena();
    this.verPerfilWebcode();
    this.mostrarPagina = true;
  }

  ngOnInit() {
  }

  mostrarFormularioLogin() {
    // console.log("mostrar login")
    if (this.mostrarLogin) {
      this.mostrarLogin = false;
    } else {
      this.mostrarLogin = true;
      this.mostrarRegistro = false;
      this.limpiarFormularioLogin();
    }
  }

  loginWithGoogle(){
    window.location.href = "https://albia.celebrasuvida.es/login-google?profile=" + this.profile_id_invitation;
  }

  loginWithFacebook(){
    window.location.href = "https://albia.celebrasuvida.es/login-facebook?profile=" + this.profile_id_invitation;
  }


  // Muestra el formulario de registro
  mostrarFormularioRegistro() {
    // console.log("mostrar registro")
    if (this.mostrarRegistro) {
      this.mostrarRegistro = false;
    } else {
      this.mostrarRegistro = true;
      this.mostrarLogin = false;
      // limpiamos el formulario
      this.limpiarFormularioRegistro();
    }
  }

  // Muestra el formulario de registro
  mostrarFormularioRecuperarContrasena() {
    // console.log("mostrar registro")
    if (this.mostrarRecuperarContrasena) {
      this.mostrarRecuperarContrasena = false;
    } else {
      this.mostrarRecuperarContrasena = true;
      // limpiamos el formulario
      this.limpiarFormularioRecuperarContrasena();
    }
  }

  // Validadaciones registro
  get nombreNoValido() {
    return this.formRegistro.get('nombre').invalid && this.formRegistro.get('nombre').touched
  }
  get apellidosNoValido() {
    return this.formRegistro.get('apellidos').invalid && this.formRegistro.get('apellidos').touched
  }
  get correoNoValido() {
    return this.formRegistro.get('correo').invalid && this.formRegistro.get('correo').touched
  }
  get telefonoNoValido() {
    return this.formRegistro.get('telefono').invalid && this.formRegistro.get('telefono').touched
  }
  get passwordNoValido() {
    return this.formRegistro.get('password1').invalid && this.formRegistro.get('password1').touched
  }
  get password2NoValido() {
    return this.formRegistro.get('password2').invalid && this.formRegistro.get('password2').touched
  }

  // crear formulario registro
  crearFormularioRegistro() {
    this.formRegistro = this.fb.group({
      nombre: [''],
      apellidos: [''],
      telefono: [''],
      correo: [''],
      password1: [''],
      password2: [''],
    });
  }

  // Llamamos al servicio de registro de usuario
  registrarUsuario() {
    //console.log("función que llamara al servicio para almacenar el invitado");
    let valoresFormularioRegistro = this.formRegistro.value;
    //console.log(valoresFormularioRegistro);

    let valoresFormularioRegistroEnviar = {
      "name": valoresFormularioRegistro.nombre,
      "lastname": valoresFormularioRegistro.apellidos,
      "email": valoresFormularioRegistro.correo,
      "phone": valoresFormularioRegistro.telefono,
      "password": valoresFormularioRegistro.password1,
      "password_confirmation": valoresFormularioRegistro.password2,
      //"invitation_token": this.token_invitation,
      "profile_id": this.profile_id_invitation
    }

    //this.loginRegistro = true;

    swal.showLoading();
    this.clearError();
    this.datosClientesService.registrarse(valoresFormularioRegistroEnviar)
      .subscribe(
        (data: any) => {
          swal.close();
          //console.log(data);
          this.access_token = data.access_token;
          //console.log(this.access_token);
          this.mostrarRegistro = false;
          this.registradoExito = true;
          this.loginRegistro = false;
          setTimeout(() => {
            this.registradoExito = false;
          }, 5000);

        },
        (error) => {
          swal.close();
          console.error(error);
          if(error.status == 422){
            this.registerError = error.error.errors;
          } else {

            swal.fire({
              icon: 'error',
              title: error.error.message,
            })

          }
          this.loginRegistro = false;
          setTimeout(() => {
            this.envioErroneoRegistro = false;
          }, 5000);

        }

      );

  }

  limpiarFormularioRegistro() {
    this.formRegistro.reset({
      nombre: '',
      apellidos: '',
      telefono: '',
      correo: '',
      password1: '',
      password2: ''
    });
  }


  // crear formulario login
  crearFormularioLogin() {
    this.formLogin = this.fb.group({
      correoLogin: ['', [Validators.required, Validators.email]],
      passwordLogin: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  limpiarFormularioLogin() {
    this.formLogin.reset({
      correoLogin: '',
      passwordLogin: '',
    });
  }

  get correoLoginNoValido() {
    return this.formLogin.get('correoLogin').invalid && this.formLogin.get('correoLogin').touched
  }
  get passwordLoginNoValido() {
    return this.formLogin.get('passwordLogin').invalid && this.formLogin.get('passwordLogin').touched
  }

  // crear formulario recuperar contraseña
  crearFormularioRecuperarContrasena() {
    this.formRecuperarContrasena = this.fb.group({
      recuperarContrasena: ['', [Validators.required, Validators.email]],
    });
  }
  // limpiar formulario recuperar contraseña
  limpiarFormularioRecuperarContrasena() {
    this.formRecuperarContrasena.reset({
      recuperarContrasena: '',
    });
  }

  // errores mail
  get recuperarContrasenaNoValido() {
    return this.formRecuperarContrasena.get('recuperarContrasena').invalid && this.formRecuperarContrasena.get('recuperarContrasena').touched
  }

  recuperarContrasena() {
    let recuperarContrasena = this.formRecuperarContrasena.value;

    let recuperarContrasenaEnviar = {
      "forgot_email": recuperarContrasena.recuperarContrasena,
    }

   // this.loginRegistro = true;
    swal.showLoading();
    this.clearError();
    this.datosClientesService.recuperarContrasena(recuperarContrasenaEnviar)
      .subscribe(
        (data: any) => {
          //console.log(data);
          swal.close();
          this.exitoRecuperarContrasena = true;
          setTimeout(() => {
            this.exitoRecuperarContrasena = false;
          }, 5000);
          this.loginRegistro = false;

        },
        (error) => {
          // console.error(error.error.errors.forgot_email);
          swal.close();

          if(error.status == 422){
            this.recoveryPasswordError = error.error.errors;
          } else {
            swal.fire({
              icon: 'error',
              title: error.error.message,
            })
          }

        }
      );
  }


  // llamamos al login, y obtenemos el token acces
  loginUsuario() {

    let valoresFormularioLogin = this.formLogin.value;   

    let valoresFormularioLoginEnviar = {
      "email": valoresFormularioLogin.correoLogin,
      "password": valoresFormularioLogin.passwordLogin,
      "profile_id": this.profile_id_invitation,
    }
    
    swal.showLoading();
    this.clearError();

    this.datosClientesService.login(valoresFormularioLoginEnviar).subscribe(
      (data: any) => {
        swal.close();
        //console.log(data);
        this.access_token = data.access_token;
        // console.log(this.access_token);
        this.mostrarLogin = false;
        // almacenamos en el local storage el acces token obtenido del login y el profile_id obtenido por la url
        //localStorage.setItem('profile_id', this.profile_id_invitation);
        localStorage.setItem('access_token', this.access_token);
        // una vez obtenido el access token, asociamos ese usuario
        this.asociarPersonaRegistrada(this.access_token, this.profile_id_invitation);
      },
      (error) => {
        console.error(error);
        swal.close();
        if(error.status == 422){
          this.loginError = error.error.errors;
        } else {
          swal.fire({
            icon: 'error',
            title: error.error.message,
          })
        }

      }

      );

  }

  // asociamos el usuario enviando el token recibido por sms y el access token obtenido del login (password y contraseña)
  asociarPersonaRegistrada(access_token, profile_id) {
    
    this.datosClientesService.asociarPersonaRegistrada(access_token, profile_id).subscribe(
      (data: any) => {
        
        // una vez asociado, pedimos toda su data
        // this.pedirProfileId();
        this.pedirPerfilPersonaRegistrada();
      },
      (error) => {
        //console.log(error);
        // console.log(error.error.message);
        // si no podemos asociarlo y la razon, es que ya se ha utilizado esa invitacion, pedimos la data
        if (error.error.message === 'La invitación no es válida o ya se ha utilizado') {
          // console.log("pedir perfil");
          //this.pedirProfileId();
        }
      }
    );
  }

  // Ver perfil usuario 

  verPerfilWebcode () {
    let profile_id = this.profile_id_invitation;
    this.datosClientesService.cargarPaginaPrincipal(profile_id).subscribe(
      (data: any) => {
        console.log("data mostrar ",data.data);          
        this.name = data.data.name;
        this.last_name = data.data.last_name
      },
      (error) => {
      }
    );
  }

  // // Pedimos el profile id de la persona
  // pedirProfileId() {

  //   let profile_id = this.profile_id_invitation;
    
  //   this.datosClientesService.cargarPaginaPrincipal(profile_id).subscribe(
  //     (data: any) => {

  //       // this.web_code = data.data.web_code;        
  //       // localStorage.setItem('web_code', data.data.web_code);
  //       // localStorage.setItem('profile_id', data.data.id);
  //       this.pedirPerfilPersonaRegistrada();
  //     },
  //     (error) => {
  //     }
  //   );

  // }



  // Pedimos Perfil / Perfiles de la persona registrada
  
  pedirPerfilPersonaRegistrada() {

    let access_token = this.access_token;
    //let profile_id = this.profile_id_invitation;

    // inicio de sesión SIN MULTI LOGIN
          // this.datosClientesService.cargarDataUsuario(access_token, profile_id).subscribe(

    // inicio de sesión CON MULTI LOGIN
          // this.datosClientesService.cargarProfilesUsuario(access_token).subscribe(

    this.datosClientesService.cargarProfilesUsuario(access_token).subscribe(

      (data: any) => {

        // check if is multi login
        if(data.data.profiles.length === 1){

          this.loginRegistro = false;

          this.dataUsuarioLogeado = data.data;
          localStorage.setItem('dataUser', JSON.stringify(this.dataUsuarioLogeado));

          // almacenamos en el local storage la data, el rol y le decimos que el token es "navegacion interna"
          // en admin un token con ese valor, lo que hara es entender que venimos de registro/login

          // set profile data on localstorage
          let simpleProfile = data.data.profiles[0];
    
          localStorage.setItem('web_code', simpleProfile.web_code);
          localStorage.setItem('profile_id', simpleProfile.profile);
          localStorage.setItem('role', simpleProfile.role);
          localStorage.setItem('token', "navegacionInterna");
          localStorage.setItem('access_token', this.access_token);

          this.sesionIniciadaExito = true;

          setTimeout(() => {
            this.sesionIniciadaExito = false;
          }, 5000);

          // Una vez terminadas todas las llamadas, navegamos a "admin", y le decimos en el token que venimos de "navegacion interna", seccion login/registro
          this.router.navigate(['/admin'], {
            queryParams: { token: 'navegacionInterna' }
          });
        
        } else if (data.data.profiles.length > 1) {

          // seteamos datos de perfiles para el front
          this.profiles = data.data.profiles;
          
          //definimos info de usuario que ha iniciado sesión.
          this.dataUsuarioLogeado = data.data;
          localStorage.setItem('dataUser', JSON.stringify(this.dataUsuarioLogeado));

          //activamos multiLogin en front
          this.multiLogin = true;
        }
      },
      (error) => {
        // console.log(error);
        this.loginRegistro = false;
        this.envioLoginErroneo = true;
        setTimeout(() => {
          this.envioLoginErroneo = false;
        }, 5000);
      }
    );
  }

  processMultiLogin(profile){

    //definimos datos localStorage
    localStorage.setItem('role', profile.role);
    localStorage.setItem('token', "navegacionInterna");
    localStorage.setItem('web_code', profile.web_code);
    localStorage.setItem('profile_id', profile.profile);
  
    this.router.navigate(['/admin'], {
      queryParams: { token: 'navegacionInterna' }
    });

    //movemos a perfil
    //this.router.navigate(["/home", profile.web_code]);

  }

  // We clear error
  clearError(){
    this.registerError = {};
    this.loginError = {};
    this.recoveryPasswordError = {};
  }

  cerrarModal() {
    this.mostrarLogin = false;
    this.mostrarRegistro = false;
    this.mostrarRecuperarContrasena = false;
  }

}