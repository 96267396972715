import { Component, OnInit } from "@angular/core";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
// La importamos por que es el modo de coger una imagen de src y convertirla en base64, simulando un servicio
import { HttpClient } from "@angular/common/http";
import swal from "sweetalert2";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-editar-profile",
  templateUrl: "./editar-profile.component.html",
  styleUrls: ["./editar-profile.component.scss"],
})
export class EditarProfileComponent implements OnInit {
  // variables
  access_token: string; // almacena el access token
  profile_id: string; // almacena el profile id
  role: string; // rol de la persona autentificada
  dataProfile: any;
  nombre: string;
  apellidos: string;
  fotografia: string;
  fotografia_wall: string; // fotografia de fondo
  fechaNacimiento: string;
  fechaDefuncion: string;
  title_epitaph: string;
  message_epitaph: string;
  formEditarProfile: FormGroup;
  modificadoExito: boolean = false;
  modificadoErroneo: boolean = false;
  mostrarPagina: boolean = false;
  mostrarLoaderRegistro: boolean = false;
  mostrarLoader: boolean;
  erroresFormularioModificacion: any;
  imagen: string; // imagen recibida del profile
  imagenFondo: string;
  mostrarImagen: boolean;
  mostrarImagenFondo: boolean;
  imagenBase64: any; // img que mandamos al servicio en base 64
  imagenWallBase64: any; // img que mandamos al servicio en base 64
  template: any; // Template seleccionado
  // fotografiaNoValida: boolean = false;
  noTienePermisos: boolean;
  web_code: string;
  wallImagePredefinida: string;
  // Variables de image-cropper
  imageChangedEvent: any = "";
  croppedImage: any = "";
  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(
    private datosClientesService: DatosClienteServicioService,
    private fb: FormBuilder,
    private http: HttpClient,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.template = localStorage.getItem("template");
    this.mostrarLoader = true;
    this.mostrarPagina = false;
    this.mostrarImagen = false;
    this.mostrarImagenFondo = false;
    this.access_token = localStorage.getItem("access_token");
    this.profile_id = localStorage.getItem("profile_id");
    this.role = localStorage.getItem("role");

    // COMPROBAMOS QUE EL ROL DE LA PERSONA LOGUEADA
    switch (this.role) {
      case "admin":
        this.noTienePermisos = false;
        this.comprobarUsuario();
        this.crearFormulario();
        break;
      case "family":
        this.noTienePermisos = true;
        this.mostrarLoader = false;
        this.mostrarPagina = false;
        break;
      case "close_friend":
        this.noTienePermisos = true;
        this.mostrarLoader = false;
        this.mostrarPagina = false;
        break;
      default:
    }

  }

  comprobarUsuario() {
    // comporbamos que el acces token y el profile_id con correctos, si la llamada da error salimos de la pagina
    this.datosClientesService
      .cargarDataAdministrador(this.access_token, this.profile_id)
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.cargarDataProfile();
        },
        (error) => {
          // si profile_id da error, es que no son correctos y redirigimos fuera de la app
          document.location.href = "http://celebrasuvida.es/";
          //console.error(error);
        }
      );
  }
  // cargamos data profile
  cargarDataProfile() {
    this.web_code = localStorage.getItem("web_code");
    // pedimos los datos
    this.datosClientesService.cargarPaginaPrincipal(this.web_code).subscribe(
      (data: any) => {
        console.log(data);
        this.dataProfile = data.data;
        this.nombre = this.dataProfile.name;
        this.apellidos = this.dataProfile.last_name;
        this.fotografia = this.dataProfile.photo;
        this.fotografia_wall = this.dataProfile.wall_image; // fotografia de fondo, sustituir
        this.fechaNacimiento = this.dataProfile.birthday;
        this.fechaDefuncion = this.dataProfile.death;
        this.title_epitaph = this.dataProfile.title_epitaph;
        this.message_epitaph = this.dataProfile.message_epitaph;
        this.template = this.dataProfile.template;
        // Metemos el template en el set item
        localStorage.setItem("template", "template1");
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );
        this.imagen = this.dataProfile.photo;
        this.croppedImage = this.dataProfile.photo;
        if (this.imagen) {
          this.mostrarImagen = true;
        }
        this.imagenFondo = this.dataProfile.photo;
        if (this.imagenFondo) {
          this.mostrarImagenFondo = true;
        }
        this.mostrarLoader = false;
        this.mostrarPagina = true;
        // console.log(this.dataProfile);
        this.cargarDataFormulario();
      },
      (error) => {
        //console.log(error);
      }
    );
  }

  // creamos el formulario
  crearFormulario() {
    this.formEditarProfile = this.fb.group({
      nombre: ["", [Validators.required, Validators.minLength(3)]],
      apellidos: ["", [Validators.required, Validators.minLength(3)]],
      fechaNacimiento: ["", Validators.required],
      fechaDefuncion: ["", Validators.required],
      fotografia: [""],
      fotografia_wall: [""],
      title_epitaph: ["", Validators.required],
      message_epitaph: [""],
    });
  }

  cargarDataFormulario() {
    this.formEditarProfile.patchValue({
      nombre: this.nombre,
      apellidos: this.apellidos,
      fechaNacimiento: this.fechaNacimiento,
      fechaDefuncion: this.fechaDefuncion,
      title_epitaph: this.title_epitaph,
      message_epitaph: this.message_epitaph,
    });
  }

  // Validadaciones del update
  get nombreNoValido() {
    return (
      this.formEditarProfile.get("nombre").invalid &&
      this.formEditarProfile.get("nombre").touched
    );
  }

  get apellidosNoValido() {
    return (
      this.formEditarProfile.get("apellidos").invalid &&
      this.formEditarProfile.get("apellidos").touched
    );
  }

  get fechaNacimientoNoValida() {
    return (
      this.formEditarProfile.get("fechaNacimiento").invalid &&
      this.formEditarProfile.get("fechaNacimiento").touched
    );
  }
  get fechaDefuncionNoValida() {
    return (
      this.formEditarProfile.get("fechaDefuncion").invalid &&
      this.formEditarProfile.get("fechaDefuncion").touched
    );
  }
  get tituloNoValida() {
    return (
      this.formEditarProfile.get("title_epitaph").invalid &&
      this.formEditarProfile.get("title_epitaph").touched
    );
  }

  // guardarmos profile editado
  guardarFormularioEditado() {
    // el formulario es incorrecto
    if (this.formEditarProfile.invalid) {
      // validaciones
      return Object.values(this.formEditarProfile.controls).forEach(
        (control) => {
          if (control instanceof FormGroup) {
            Object.values(control.controls).forEach((control) =>
              control.markAsTouched()
            );
          } else {
            control.markAsTouched();
          }
        }
      );
    } else {
      // el formulario es correcto
      this.enviarModificacionPerfil();
    }
  }

  enviarModificacionPerfil() {
    let valores: any = {
      name: this.formEditarProfile.value.nombre,
      lastname: this.formEditarProfile.value.apellidos,
      birthday: this.formEditarProfile.value.fechaNacimiento,
      death: this.formEditarProfile.value.fechaDefuncion,
      photo_base64: this.imagenBase64, // imagen de perfil
      wall_image_base64: this.imagenWallBase64, // imagen de fondo
      title_epitaph: this.formEditarProfile.value.title_epitaph,
      message_epitaph: this.formEditarProfile.value.message_epitaph,
      template: "1",
      wallImagePredefinida: this.wallImagePredefinida,
    };
    valores.wall_image_base64 = this.wallImagePredefinida
      ? null
      : valores.wall_image_base64;
    this.mostrarLoaderRegistro = true;
    this.datosClientesService
      .modificarProfile(valores, this.profile_id, this.access_token)
      .subscribe(
        (data: any) => {
          swal.fire({
            title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
            text: this.translateService.instant('DATA_SAVED_SUCCESSFULLY'),
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: "#444444",
            confirmButtonText: "OK",
          });
          setTimeout(() => {
            swal.close();
          }, 2000);
        },
        (error) => {
          this.erroresFormularioModificacion = [
            error.error.errors.photo,
            error.error.errors.name,
            error.error.errors.lastname,
            error.error.errors.lastname,
            error.error.errors.birthday,
          ];
          this.modificadoErroneo = true;
          setTimeout(() => {
            this.modificadoErroneo = false;
          }, 2000);
        }
      );
  }

  /**
   *  Titulos de epitaceos predefinidos
   * @param value
   */
  cambioTituloEpitaceo(value: string) {
    if (value !== "--") {
      this.formEditarProfile.patchValue({
        title_epitaph: value,
      });
    }
  }

  /**
   *  Convertimos imagenes preseleccionadas en base64, para ello hemos de simular un servicio
   * @param img
   */
  imagenPreseleccionada(img, id) {
    this.wallImagePredefinida = id;
    this.http.get(img.src, { responseType: "blob" }).subscribe((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64data = reader.result;
        // Convertimos la imagen preseleccionada en base64 y se lo añadimos al profile
        this.imagenWallBase64 = base64data;
        this.fotografia_wall = this.imagenWallBase64;
      };
      reader.readAsDataURL(res);
    });

    var element1 = document.getElementById("1");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("2");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("3");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("4");
    element1.classList.remove("imagenPreseleccionadaSelected");

    document.getElementById(id).classList.add("imagenPreseleccionadaSelected");
  }

  /**
   * Cuando adjuntamos una imagen de fondo, quitamos la clase selected a las imagen preseleccionadas
   */
  borrarImagenFondoPreseleccionada() {
    var element1 = document.getElementById("imagen1");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagen2");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagen3");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagen4");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagen5");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagen6");
    element1.classList.remove("imagenPreseleccionadaSelected");
  }

  imagenPreseleccionadaPersona(img, id) {
    this.http.get(img.src, { responseType: "blob" }).subscribe((res) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64data = reader.result;
        // Convertimos la imagen preseleccionada en base64 y se lo añadimos al profile
        this.imagenBase64 = base64data;
        this.imagen = this.imagenBase64;
      };
      reader.readAsDataURL(res);
    });

    var element1 = document.getElementById("imagenPersona1");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagenPersona2");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagenPersona3");
    element1.classList.remove("imagenPreseleccionadaSelected");

    var element1 = document.getElementById("imagenPersona4");
    element1.classList.remove("imagenPreseleccionadaSelected");

    document.getElementById(id).classList.add("imagenPreseleccionadaSelected");
  }

  // INICIO FUNCIONES IMAGE CROPPER
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.imagen = event.base64;
    this.imagenBase64 = event.base64;

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  // FIN FUNCIONES IMAGE CROPPER
  /////////////
}
