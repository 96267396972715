import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  
  title = 'Albia';
  defaultLanguage = 'es';
  
  constructor(private translateService: TranslateService) {

    //si ya hay idoma seleccionado
    if(localStorage.getItem("lang")) {
      this.translateService.setDefaultLang(localStorage.getItem("lang"));
      this.translateService.use(localStorage.getItem("lang"));
    }else{
      this.translateService.use(this.defaultLanguage);
      localStorage.setItem("lang", this.defaultLanguage);
    }
    
  }

}
