import { Component, OnInit } from "@angular/core";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import swal from "sweetalert2";
import { HelperService } from "src/app/helpers/helper";
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { exit } from "process";

@Component({
  selector: "app-familiares-allegados",
  templateUrl: "./familiares-allegados.component.html",
  styleUrls: ["./familiares-allegados.component.scss"],
})
export class FamiliaresAllegadosComponent implements OnInit {
  access_token: string; // almacena el access token
  role: string; // role de la persona autentificada
  profile_id: string; // profile
  web_code: string;
  mostrarAdmin: boolean = false; // todo lo englobado por esta boleano, solo podra ser visto por administradores
  mostrarFormulario: boolean = false; // formulario para añadir allegados o familiares
  mostrarPagina: boolean;
  mostrarPaginaLoad: boolean = true;
  envioExitoso: boolean = false;
  envioErroneo: boolean = false;
  copiadoExitoso: boolean = false;
  borradoExitoso: boolean = false;
  mensajeError: string = "";
  erroresFormulario: any;
  listaFamiliaresAllegados: any;
  tipoInvitacion: string;
  listaEnlaces: any = [];
  disabledButtonAdmin: boolean = false;
  disabledButtonFamily: boolean = false;
  disabledButtonClose_friend: boolean = false;
  noTienePermisos: boolean = false;
  arrayComentarios: any = [];
  arrayComentariosAprobados: any = [];
  arrayComnetariosNoAprobados: any = [];
  arrayInvitaciones: any = [];
  conteoComentariosAprobados: number;
  conteoComentariosNoAprobados: number;
  tab = 1;
  template: string;
  arrayVelas: any = [];
  arrayVelasAprobadas: any = [];
  arrayVelasNoAprobadas: any = [];
  comentariosPendientesModeracion: string = "0";
  velasPendientesModeracion: string = "0";
  colapsar_comentarios:boolean = true;
  colapsar_luces:boolean = true;
  copiarEnlaceHome:any;
  change_role: any;
  rolefilter: string = "initial";
  original_list:any;
  birthdate_show:any;
  advancedProfile: string;
  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(
    private datosClientesService: DatosClienteServicioService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    ) {
 
    this.access_token = localStorage.getItem("access_token");
    this.role = localStorage.getItem("role");
    this.profile_id = localStorage.getItem("profile_id");
    this.advancedProfile = localStorage.getItem("advancedProfile");
        
    if (this.role === "admin") {
      this.mostrarAdmin = true;
      this.cargarFamiliaresAllegados();
      this.obtenerComentarios();
      this.obtenerVelas();
      this.generarInvitacion();
    }
    if (this.role === "family") {
      this.noTienePermisos = true;
      this.mostrarPaginaLoad = false;
    }
    if (this.role === "close_friend") {
      this.noTienePermisos = true;
      this.mostrarPaginaLoad = false;
    }

    if (this.access_token) {
      // console.log("Role autentificado familiares y allegados --> " + this.role);
    } else {
      document.location.href = "http://celebrasuvida.es/";
    }

    this.tab =
      parseInt(new URLSearchParams(window.location.search).get("tab")) || 1;
    //console.log('tab enviado ' , this.tab);
  }

  ngOnInit() {

    this.helperService.updateQuantityNotificaciones();

    this.template = localStorage.getItem("template");
    // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
    let rutaCss: any = document.getElementById("templates-albia");
    rutaCss.setAttribute(
      "href",
      "assets/css/template1.css"
    );
    this.notificacionesPendientes();

  }

  collapse() {
    this.colapsar_comentarios=!this.colapsar_comentarios;
  }

  collapse_lights() {
    this.colapsar_luces=!this.colapsar_luces;
    console.log(this.colapsar_luces);
    
  }

  generarInvitacion() {
    // generamos el compartir
    this.web_code = localStorage.getItem("web_code");
    let url = "https://csv-demo.informatica-integral.com/home/" + this.web_code;
    this.datosClientesService.obtenerInvitaciones().subscribe(
      (data: any) => {
        console.log(data.data);
        this.arrayInvitaciones = data.data;
        //ordenamos array por id
        console.log("Array invitaciones: ",this.arrayInvitaciones);
        
        this.arrayInvitaciones.sort((a, b) => a.id-b.id);
        this.arrayInvitaciones.push({
          role: "Resto_invitados",
          url: url,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  filtrarUsuarios (rolefilter) {
  //  console.log("rolefilter",rolefilter); 
    this.rolefilter = rolefilter;    
    this.listaFamiliaresAllegados = this.original_list;

    if (rolefilter!='all') {
      let filtrado = this.listaFamiliaresAllegados.filter(x=>x.profile.role===rolefilter);
      this.listaFamiliaresAllegados = filtrado;
      // console.log("filtrado", filtrado);
    } else {
      this.listaFamiliaresAllegados = this.original_list;
    }

   

    //return this.listaFamiliaresAllegados;

  }

  cargarFamiliaresAllegados() {
    //   console.log("lista familiares y allegados");
    //  console.log(this.access_token, this.profile_id);

    this.datosClientesService
      .cargarListaFamiliaresAllegados(this.access_token, this.profile_id)
      .subscribe(
        (data: any) => {
          //    console.log(data);
          this.listaFamiliaresAllegados = data.data;

          this.mostrarPaginaLoad = false;
          this.mostrarPagina = true;
          this.original_list = this.listaFamiliaresAllegados;
          console.log("original_list",this.original_list);
          
        },
        (error) => {
          // si el acces token o el profile_id dan error, es que no son correctos y redirigimos fuera de la app
          // document.location.href = 'http://celebrasuvida.es/';
          console.error("error ", error);
        }
      );
  }

  // FUNCION COPIAR ENLACE
  /**
   * Copiamos el enlace
   */
   copiarEnlace() {

    //produccion
    this.copiarEnlaceHome = "https://csv-demo.informatica-integral.com/invitation?profile=" + this.web_code;

    //local
    //this.copiarEnlaceHome = "http://localhost:4200/invitation?profile=" + this.web_code;

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copiarEnlaceHome;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  
    

    swal.fire({
      title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
      text: this.translateService.instant('LINK_COPIED_SUCCESSFULLY'),
      icon: "success",
      iconColor: "#444444",
      confirmButtonColor: '#444444',
      confirmButtonText: 'OK',
    });
    //  console.log(data);
    setTimeout(function () {
      swal.close();
    }, 3000);
  }

  // FUNCION que refresca el enlace
  borrarEnlace(lista: any, listaRole: string) {
    // BORRAMOS DE LA LISTA DE ENLACES, EL ELEMENTO SELECCIONADO+
    // console.log("borrar ", lista.id);
    this.datosClientesService
      .refrescamosInvitacion(this.profile_id, this.access_token, lista.id)
      .subscribe(
        (data: any) => {
          swal.fire({
            title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
            text: this.translateService.instant('THE_INVITATION_LINK_HAS_BEEN_SUCCESSFULLY_REGENERATED'),
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: "#444444",
            confirmButtonText: "OK",
          });
          //  console.log(data);
          this.generarInvitacion();
          setTimeout(function () {
            swal.close();
          }, 2000);
        },
        (error) => {
          swal.fire(
            this.translateService.instant('ERROR'),
            this.translateService.instant('ERROR_OCCURRED'),
            'error'
          );
          setTimeout(function () {
            swal.close();
          }, 2000);
          console.error("error ", error);
        }
      );
  }

  /**
   * Obtener comentarios
   */
  obtenerComentarios() {
    this.datosClientesService
      .obtenemosComentariosParaValidar(this.profile_id, this.access_token)
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.arrayComentarios = data.data;
                  
          // Formatear ISO formato fecha
          this.arrayComentarios.forEach(function(part, index) {
            if (part.created_at) { 
              part.created_at = part.created_at.replace(' ', 'T');
            }
          });

          this.arrayComentariosAprobados = this.arrayComentarios.filter(
            (obj) => obj.approved === 1
          );
          this.arrayComnetariosNoAprobados = this.arrayComentarios.filter(
            (obj) => obj.approved === 0
          );
        },
        (error) => {
          console.error("error ", error); 
        }
      );
  }

  /**
   * Borrar comentario
   */
  borrarComentario(i: any, tipo: string) {

    let comment: any;
    let comment_id: any;
    if (tipo === "noAprobado") {
      //console.log('comentario no aprobado ', i );
      comment = this.arrayComnetariosNoAprobados[i];
      comment_id = this.arrayComnetariosNoAprobados[i].id;
    }

    if (tipo === "aprobado") {
      //console.log('comentario aprobado ', i );
      comment = this.arrayComentariosAprobados[i];
      comment_id = this.arrayComentariosAprobados[i].id;
    }

    // sweet alert
    swal
      .fire({
        title: this.translateService.instant('DELETE_MEMORY'),
        text: this.translateService.instant('IF_PRESS_YES_MEMORY_WILL_DELETE'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio

          this.datosClientesService
            .borrarComentarioAutenticado(
              this.profile_id,
              this.access_token,
              comment_id
            )
            .subscribe(
              (data: any) => {
                //console.log(data);
                /*
            swal.fire(
              'Borrado!',
              'El comentario ha sido borrado con éxito.',
              'success'
            );
            */
                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('MEMORY_HAS_BEEN_SUCCESSFULLY_DELETED'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });
                setTimeout(function () {
                  swal.close();
                }, 2000);
                /*
            this.arrayComentarios = this.arrayComentarios.filter(function (value, index, arr) {
              return value.id !== comment.id;
            });
            */
                this.obtenerComentarios();
                this.helperService.updateQuantityNotificaciones();
              },
              // da error
              (error) => {
                //console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

  /**
   * Aprobar comentario
   */
  aprobarComentario(i: any) {
    let comment_id = this.arrayComnetariosNoAprobados[i].id;
    swal
      .fire({
        title: this.translateService.instant('APPROVE_MEMORY'),
        text: this.translateService.instant('IF_PRESS_YES_MEMORY_WILL_APPROVE'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio

          this.datosClientesService
            .aprobarComentarioAutenticado(
              this.profile_id,
              this.access_token,
              comment_id
            )
            .subscribe(
              (data: any) => {

                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('THE_MEMORY_HAS_BEEN_APPROVED_SUCCESSFULLY'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });

                setTimeout(function () {
                  swal.close();
                }, 2000);
                
                //this.arrayComentarios[i].approved = 1;
                this.obtenerComentarios();
                this.helperService.updateQuantityNotificaciones();
              },
              // da error
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

    /**
   * Seleccionar rol invitado
   */

  seleccionarRol(infoInvitado) {
      swal
      .fire({
        title: this.translateService.instant('¿Estás seguro de asignar el nuevo rol al usuario?'),
        text: this.translateService.instant('Al pulsa si se cambiará el rol automáticamente'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio
          this.datosClientesService
            .asignarRolInvitado(
              this.profile_id,
              this.access_token,
              infoInvitado.id,
              infoInvitado.profile.role
            )
            .subscribe(
              (data: any) => {

                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('Rol cambiado correctamente') + "</h5>",
                  text: this.translateService.instant('El rol del usuario ha sido correctamente modificado.'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });


                setTimeout(function () {
                  swal.close();
                }, 2000);

                this.cargarFamiliaresAllegados();
/*
                this.listaFamiliaresAllegados =
                  this.listaFamiliaresAllegados.filter(function (
                    value,
                    index,
                    arr
                  ) {
                   // return value.id !== lista.id;
                  });
*/

              },
              // da error
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
    }

  /**
   *
   */
  borrarInvitado(lista: any) {
    swal
      .fire({
        title: this.translateService.instant('DO_YOU_WANT_DELETE_GUEST'),
        text: this.translateService.instant('IF_PRESS_YES_GUEST_WILL_BE_DELETED'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio

          this.datosClientesService
            .borrarInvitadoAutenticado(
              this.profile_id,
              this.access_token,
              lista.id
            )
            .subscribe(
              (data: any) => {
                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('GUEST_ACCESS_HAS_BEEN_REMOVED'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });
                setTimeout(function () {
                  swal.close();
                }, 2000);

                this.cargarFamiliaresAllegados();
/*
                this.listaFamiliaresAllegados =
                  this.listaFamiliaresAllegados.filter(function (
                    value,
                    index,
                    arr
                  ) {
                    return value.id !== lista.id;
                  });
*/

              },
              // da error
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

  // Obtenemos todas las velas (aprovadas y no aprovadas)
  obtenerVelas() {
    this.datosClientesService
      .obtenemosVelasValidar(this.profile_id, this.access_token)
      .subscribe(
        (data: any) => {
          this.arrayVelas = data.data;
          this.arrayVelasAprobadas = this.arrayVelas.filter(
            (obj) => obj.approved === 1
          );
          this.arrayVelasNoAprobadas = this.arrayVelas.filter(
            (obj) => obj.approved === 0
          );
        },
        (error) => {
          console.error("error ", error);
        }
      );
  }

  // Borramos la vela seleccionada
  borrarVela(i: any, tipo: string) {
    let candle: any;
    let candle_id: any;

    if (tipo === "noAprobado") {
      candle = this.arrayVelasNoAprobadas[i];
      candle_id = this.arrayVelasNoAprobadas[i].id;
    }

    if (tipo === "aprobado") {
      candle = this.arrayVelasAprobadas[i];
      candle_id = this.arrayVelasAprobadas[i].id;
    }

    // sweet alert
    swal
      .fire({
        title: this.translateService.instant('DELETE_ETERNAL_LIGHT'),
        text: this.translateService.instant('IF_PRESS_YES_LIGHT_WILL_BE_DELETED'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio

          this.datosClientesService
            .borrarVelas(this.profile_id, this.access_token, candle_id)
            .subscribe(
              (data: any) => {
                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('ETERNAL_LIGHT_HAS_BEEN_SUCCESSFULLY_DELETED'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });
                setTimeout(function () {
                  swal.close();
                }, 2000);
                this.obtenerVelas();

                //actualizamos números
                this.helperService.updateQuantityNotificaciones();

              },
              // da error
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

  /**
   * Aprobar vela
   */
  aprobarVela(i: any) {
    let candle_id = this.arrayVelasNoAprobadas[i].id;
    swal
      .fire({
        title: this.translateService.instant('APPROVE_ETERNAL_LIGHT'),
        text: this.translateService.instant('IF_PRESS_YES_ETERNAL_LIGHT_WILL_APPROVED'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        //console.log(result);
        // Si pulsamos borrar en el alert, llamaremos al servicio
        if (result.isConfirmed) {
          // Llamamos al servicio
          //console.log(this.profile_id, this.access_token, candle_id);
          this.datosClientesService
            .aprobarVelaAutenticado(
              this.profile_id,
              this.access_token,
              candle_id
            )
            .subscribe(
              (data: any) => {
                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('ETERNAL_LIGHT_HAS_BEEN_SUCCESSFULLY_APPROVED'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: "#444444",
                  confirmButtonText: "OK",
                });
                setTimeout(function () {
                  swal.close();
                }, 2000);
                //this.arrayComentarios[i].approved = 1;
                this.obtenerVelas();
                this.helperService.updateQuantityNotificaciones();
              },
              // da error
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                );
                setTimeout(function () {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

  // Notificaciones pendientes (velas y comentarios)
  notificacionesPendientes() {

    // Cargamos las notificaciones pendientes en el inicio
    this.velasPendientesModeracion = localStorage.getItem("velasPendientesModeracion");
    this.comentariosPendientesModeracion = localStorage.getItem("comentariosPendientesModeracion");

    // Cada 2 segundos comporbamos si hay nuevas notificaciones en el local storage
    setInterval(() => {
      this.velasPendientesModeracion = localStorage.getItem("velasPendientesModeracion");
      this.comentariosPendientesModeracion = localStorage.getItem("comentariosPendientesModeracion");
    }, 1000);
  }
  //////
}
