import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { DatosClienteServicioService } from 'src/app/servicios/datos-cliente-servicio.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
// ES6 Modules or TypeScript
import swal from 'sweetalert2';
import { PageEvent } from "@angular/material";
import { HelperService } from 'src/app/helpers/helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-muro-firmas',
  templateUrl: './muro-firmas.component.html',
  styleUrls: ['./muro-firmas.component.scss']
})
export class MuroFirmasComponent implements OnInit, AfterViewChecked {

  // variables
  dataUserProfile: any = '';
  dataUserProfileID: any = '';
  profile_id: string;
  dataMuroFirmaPublico: any;
  dataMuroFirmaAutenticado: any;
  advancedProfile: string;
  mostrarPagina: boolean;
  autenticado: boolean = false;
  access_token: string; // almacena el access token
  role: string = ''; // rol de la persona autentificada
  formInsertarComentarioPublico: FormGroup;
  formInsertarComentarioAutenticado: FormGroup;
  imagenBase64: any; // img que mandamos al servicio en base 64
  imagenBase64Autenticado: any;
  fotografiaNoValida: boolean = false;
  fotografiaAutenticadoNoValida: boolean = false;
  mostrarFormularioComentariosPublicos: boolean = false;
  mostrarFormularioComentariosAutenticado: boolean = false;
  mostrarLoaderRegistroComentarioPublico: boolean = false;
  mostrarLoaderRegistroComentarioAutenticado: boolean = false;
  exitoRegistroComentarioPublico: boolean = false;
  errorRegistroComentarioPublico: boolean = false;
  exitoRegistroComentarioAutenticado: boolean = false;
  errorRegistroComentarioAutenticado: boolean = false;
  subidaDocumentosAutentificado: any = [];
  idComentarioEditar: number;
  template: string;
  web_code: string; // parametro recibido por url, a través de este parametro podemos obtener el profifle id del cliente
  totalSignaturePublic = 0;
  pageSizePublic = 15;
  totalPagesPublic = 0;
  currentPagePublic = 0;
  totalSignaturePrivate = 0;
  totalPagesPrivate = 0
  pageSizePrivate = 15;
  currentPagePrivate = 0;
  isShowForm: boolean = false;
  contador = 0;

  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)"

  constructor(
    private router: Router,
    private datosClientesService: DatosClienteServicioService,
    private fb: FormBuilder,
    private fbAutenticado: FormBuilder,
    private helperService: HelperService,
    private translateService: TranslateService
    ) {
    // recogemos el idCliente del local Storage
    this.profile_id = localStorage.getItem('profile_id');
    this.access_token = localStorage.getItem('access_token');
    this.role = localStorage.getItem('role');
    this.advancedProfile = localStorage.getItem('advancedProfile');

    if (localStorage.getItem('dataUser') !== null) {
      this.dataUserProfile = JSON.parse(localStorage.getItem('dataUser'));
      this.dataUserProfileID = this.dataUserProfile.id;
    }

    // si existe el id que recibimos por url, llamamos al servicio
    if (this.profile_id) {
      // llamamos al servicio
      this.cargarMuroFirmas();
    } else {
      // si no existe elel local storage, redireccionamos y lo sacamos de la web
      document.location.href = 'http://celebrasuvida.es/';
    }

   

  }


  ngOnInit() {
    this.web_code = localStorage.getItem("web_code");
    // this.obtenerTemplate(this.web_code);
    // Creamos el formulario para insertar comentarios publicos
    this.crearFormularioInsertarComentarioPublico();
    this.crearFormularioInsertarComentarioPrivado();
  }

  onKeyUp(valor){
    this.contador = 500 - valor.length;      
   }

   



  ngAfterViewChecked() {

    if(this.totalPagesPublic > 0 ){
      const list = document.getElementsByClassName('mat-paginator-range-label');
      list[0].innerHTML = this.translateService.instant('PAGE') + " " + (this.currentPagePublic+1) + " " + this.translateService.instant('OF') + " " + (this.totalPagesPublic);  
     }; 

    if(this.totalPagesPrivate > 0 ){
      const list2 = document.getElementsByClassName('mat-paginator-range-label');
      list2[1].innerHTML = this.translateService.instant('PAGE') + " " + (this.currentPagePrivate+1) + " " + this.translateService.instant('OF') + " " + (this.totalPagesPrivate);
    }; 

}

  // cargamos la info del servicio, por ahora esta en un json local
  cargarMuroFirmas() {

    if (!this.access_token) {
      
      this.cargarMuroNoAutenticado();
      this.mostrarFormularioComentariosPublicos = true;
      this.mostrarFormularioComentariosAutenticado = false;
      
    } else {

      this.mostrarFormularioComentariosPublicos = false;
      this.mostrarFormularioComentariosAutenticado = true;
      this.cargarMuroNoAutenticado();
      this.cargarMuroAutenticado();
    }

  }
  /**
   * Servicio que carga los comentarios para usuarios NO AUTENTICADOS
   */
  cargarMuroNoAutenticado() {

    const params = {
      page: this.currentPagePublic + 1,
    }
    this.datosClientesService.cargarMuroFirmasSinAutentificacionPaginate(this.profile_id, params).subscribe(
      (data: any) => {
        this.dataMuroFirmaPublico = data.data.items;

        // Formatear ISO formato fecha
        this.dataMuroFirmaPublico.forEach(function(part, index) {
          if (part.created_at) { 
            part.created_at = part.created_at.replace(' ', 'T');
          }
        });

        this.totalSignaturePublic = data.data.total;
        this.pageSizePublic = data.data.per_page;
        this.totalPagesPublic = Math.ceil(this.totalSignaturePublic/this.pageSizePublic);
        this.mostrarPagina = true;    
        
        window.scrollTo({top: 0, behavior: 'smooth'});   
      },
      (error) => {
        //console.error(error);
        this.mostrarPagina = false;
      }
    );
  }

  /**
   * Servicio que carga los comentarios para usuarios SI AUTENTICADOS
   */
  cargarMuroAutenticado() {

    //cargamos el muro de firmas familiar solo para admin y familiar, o si el perfil es simple.
    if(this.role == "admin" || this.role == "family" || this.advancedProfile == "0"){
  
      const params = {
        page: this.currentPagePrivate + 1,
      }
  
      //let currentPagePublicTemporal = this.currentPagePublic + 1;
      this.datosClientesService.cargarMuroFirmasConAutentificacionPaginado(this.access_token, this.profile_id, params).subscribe(
        (data: any) => {
  
          this.dataMuroFirmaAutenticado = data.data.items;
  
          // Formatear ISO formato fecha
          this.dataMuroFirmaAutenticado.forEach(function(part, index) {
            if (part.created_at) { 
              part.created_at = part.created_at.replace(' ', 'T');
            }
          });
  
          this.totalSignaturePrivate = data.data.total;
          this.pageSizePrivate = data.data.per_page;
          this.totalPagesPrivate = Math.ceil(this.totalSignaturePrivate/this.pageSizePrivate);
          this.mostrarPagina = true;
          
          window.scrollTo({top: 0, behavior: 'smooth'});   
        },
        (error) => {
          //console.error(error);
          this.mostrarPagina = false;
        }
      );

    }

  }

  /*
      ==================================== 
      Inicio insertar comentarios publicos 
      ====================================
  */
  // Creamos el formulario
  crearFormularioInsertarComentarioPublico() {
    this.formInsertarComentarioPublico = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(75)]],
      comentario: ['', [Validators.minLength(10), Validators.maxLength(500)]],
      fotografia: [''],
      aceptarTerminos: [false, Validators.requiredTrue],
      consentimiento: [false, Validators.requiredTrue]
    });
  }
  // Controlamos los errores del formulario
  get nombreNoValido() {
    return this.formInsertarComentarioPublico.get('nombre').invalid && this.formInsertarComentarioPublico.get('nombre').touched
  }

  // get comentarioNoValido() {
  //   return this.formInsertarComentarioPublico.get('comentario').invalid && this.formInsertarComentarioPublico.get('comentario').touched
  // }

  get aceptarTerminosNoValido() {
    return this.formInsertarComentarioPublico.get('aceptarTerminos').invalid && this.formInsertarComentarioPublico.get('aceptarTerminos').touched
  }
 
  get consentimientoNoValido() {
    return this.formInsertarComentarioPublico.get('consentimiento').invalid && this.formInsertarComentarioPublico.get('consentimiento').touched
  }
 



  // Comprobamos que el formulario es correcto
  guardarFormularioPublico() {

    // el formulario es incorrecto
    if (this.formInsertarComentarioPublico.invalid) {
      // validaciones
      return Object.values(this.formInsertarComentarioPublico.controls).forEach(control => {

        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => control.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });

    } else {
      // el formulario es correcto
      this.enviarComentarioPublico();
    }
  }



  /**
   *  Recogemos la imagen y la convertimos en Base64
   * @param input 
   */
  onFileSelect(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagenBase64 = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  /**
   *  Limpiamos el formulario
   */
  limpiarFormularioComentarioPublico() {
    this.formInsertarComentarioPublico.reset({
      nombre: '',
      comentario: '',
      fotografia: '',
    });
  }

  /**
   * Enviamos al servicio el comentario publico
   */
  enviarComentarioPublico() {

    // data que enviamos
    let valores: any = {
      title: this.formInsertarComentarioPublico.value.nombre,
      message: this.formInsertarComentarioPublico.value.comentario,
      file_base64: this.imagenBase64
    };

    this.mostrarLoaderRegistroComentarioPublico = true;
    this.datosClientesService.insertarComentarioPublico(valores, this.profile_id).subscribe(

      (data: any) => {
        this.limpiarFormularioComentarioPublico();
        swal.fire({
          title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
          text: this.translateService.instant('MESSAGE_SENT_SUCCESSFULLY_WILL_BE_PUBLISHED_WHEN_ADMIN_ACCEPT'),
          icon: "success",
          iconColor: "#444444",
          confirmButtonColor: '#444444',
          confirmButtonText: 'OK',
      })
        setTimeout(() => {
          swal.close();
          location.reload();
        }, 2000);
        this.isShowForm = false;
      },
      (error) => {


          console.log("entro en error de comentario");
          
          swal.fire(
            this.translateService.instant('ERROR'),
            this.translateService.instant('ERROR_OCCURRED'),
            'error'
          )
          setTimeout(function () {
            swal.close();
            location.reload();
          }, 2000);


        console.error(error);
        this.limpiarFormularioComentarioPublico();
        this.mostrarLoaderRegistroComentarioPublico = false;
        this.errorRegistroComentarioPublico = true;
        setTimeout(() => {
          this.errorRegistroComentarioPublico = false;
        }, 2000);

      }
    );
  }
  /*
      ==================================== 
      Fin insertar comentarios publicos 
      ====================================
  */

  /*
      ==================================== 
      Inicio insertar comentarios autenticado 
      ====================================
  */
  // Creamos el formulario
  crearFormularioInsertarComentarioPrivado() {
    this.formInsertarComentarioAutenticado = this.fbAutenticado.group({
      nombreFormAutenticado: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(75)]],
      comentarioFormAutenticado: ['', [Validators.minLength(10), Validators.maxLength(500)]],
      publicoFormAutenticado: ['1', Validators.required],
      fotografiaFormAutenticado: [''],
      aceptarTerminos: [false, Validators.requiredTrue],
      consentimiento: [false, Validators.requiredTrue]
    });
  }
  // Controlamos los errores del formulario
  get nombreAutenticadoNoValido() {
    return this.formInsertarComentarioAutenticado.get('nombreFormAutenticado').invalid && this.formInsertarComentarioAutenticado.get('nombreFormAutenticado').touched
  }

  // get comentarioAutenticadoNoValido() {
  //   return this.formInsertarComentarioAutenticado.get('comentarioFormAutenticado').invalid && this.formInsertarComentarioAutenticado.get('comentarioFormAutenticado').touched
  // }

  get aceptarTerminosAutenticadoNoValido() {
    return this.formInsertarComentarioAutenticado.get('aceptarTerminos').invalid && this.formInsertarComentarioAutenticado.get('aceptarTerminos').touched
  }

  get consentimientoAutenticadoNoValido() {
    return this.formInsertarComentarioAutenticado.get('consentimiento').invalid && this.formInsertarComentarioAutenticado.get('consentimiento').touched
  }

  onFileSelectAutenticado(input) {

    for (let x = 0; x < input.files.length; x++) {
      this.subidaDocumentosAutentificado.push(input.files[x]);
    }

  }



  
  // Comprobamos que el formulario es correcto
  guardarFormularioAutenticado() {

    // el formulario es incorrecto
    if (this.formInsertarComentarioAutenticado.invalid) {
      // validaciones
      this.fotografiaAutenticadoNoValida = true;
      return Object.values(this.formInsertarComentarioAutenticado.controls).forEach(control => {

        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => control.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });

    } else {
      // el formulario es correcto
      this.fotografiaAutenticadoNoValida = false;
      this.enviarComentarioAutenticado();
    }
  }

  /**
   *  Limpiamos el formulario
   */
  limpiarFormularioComentarioAutenticado() {
    this.formInsertarComentarioAutenticado.reset({
      nombreFormAutenticado: '',
      comentarioFormAutenticado: '',
      publicoFormAutenticado: 'false',
      fotografiaFormAutenticado: '',
    });
  }

  /**
   *  Enviamos al servicio el comentario de un usuario autentificado
   */
  enviarComentarioAutenticado() {

    // por cada foto enviamos petición de inserción
    for (let x = 0; x < this.subidaDocumentosAutentificado.length; x++) {

     // data que enviamos
    let formData = new FormData();
    formData.append("title", this.formInsertarComentarioAutenticado.value.nombreFormAutenticado);
    formData.append("message", this.formInsertarComentarioAutenticado.value.comentarioFormAutenticado);
    formData.append("public", this.formInsertarComentarioAutenticado.value.publicoFormAutenticado);
    formData.append("file", this.subidaDocumentosAutentificado[x]);

    let params = new HttpParams();
    const options = {
      params: params,
      reportProgress: true,
    };

    this.mostrarLoaderRegistroComentarioAutenticado = true;

    this.datosClientesService.insertarComentarioAutenticado(formData, this.profile_id, this.access_token, options).subscribe(

      (data: any) => {
        this.limpiarFormularioComentarioPublico();
        this.mostrarLoaderRegistroComentarioAutenticado = false;
        this.isShowForm = false;
        swal.fire({
          title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
          text: this.translateService.instant('MEMORY_HAS_BEEN_SENT_SUCCESSFULL'),
          icon: "success",
          iconColor: "#444444",
          confirmButtonColor: '#444444',
          confirmButtonText: 'OK',
      })
        setTimeout(() => {
          swal.close();
        }, 2000);
      },
      (error) => {
        swal.fire(
          this.translateService.instant('ERROR'),
          this.translateService.instant('ERROR_OCCURRED'),
          'error'
        )
        setTimeout(function () {
          swal.close();
          //location.reload();
        }, 2000);
        console.error(error);
        this.limpiarFormularioComentarioPublico();
        this.mostrarLoaderRegistroComentarioAutenticado = false;
        this.errorRegistroComentarioAutenticado = true;
        setTimeout(() => {
          this.errorRegistroComentarioAutenticado = false;
        }, 2000);

      }
    );

    }

  }

  /*
  ==================================== 
  Fin insertar comentarios autenticado 
  ====================================
*/

  /*
    ==================================== 
    Borrar comentario 
    ====================================
  */
  borrarComentario(id: number) {
    let comment_id = id;
    // sweet alert
    swal.fire({
      title: this.translateService.instant('DELETE_MEMORY'),
      text: this.translateService.instant('IF_PRESS_YES_MEMORY_WILL_DELETE'),
      icon: 'warning',
      iconColor: "#444444",
      showCancelButton: true,
      confirmButtonColor: '#444444',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translateService.instant('YES'),
      cancelButtonText: this.translateService.instant('NO'),
    }).then((result) => {
      // Si pulsamos borrar en el alert, llamaremos al servicio
      if (result.isConfirmed) {
        // Llamamos al servicio
        this.datosClientesService.borrarComentarioAutenticado(this.profile_id, this.access_token, comment_id).subscribe(
          (data: any) => {
            // tiene exito
            swal.fire({
              title: "<h5 style='color:#444444'>" + this.translateService.instant('DELETED') + "</h5>",
              text: this.translateService.instant('MEMORY_HAS_BEEN_SUCCESSFULLY_DELETED'),
              icon: "success",
              iconColor: "#444444",
              confirmButtonColor: '#444444',
              confirmButtonText: 'OK',
          })
            setTimeout(function () {
              swal.close();
              //location.reload();
            }, 2000);
          },
          // da error
          (error) => {
            console.error(error);
            swal.fire(
              this.translateService.instant('ERROR'),
              this.translateService.instant('ERROR_OCCURRED'),
              'error'
            )
            setTimeout(function () {
              swal.close();
              //location.reload();
            }, 2000);
          }
        );
      }
    })
  }

  /*
    ==================================== 
    Editar recuerdo 
    ====================================
  */

  editarComentario(firma: any) {
    if (this.role === 'admin' || firma.user.id === this.dataUserProfileID) {
      this.idComentarioEditar = firma.id;
    }
  }

  borrarImagen(firma:any){
    swal.fire({
      title: this.translateService.instant('DELETE_IMAGE'),
      text: this.translateService.instant('IF_PRESS_YES_MEMORY_IMAGE_WILL_DELETE'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: "#444444",
      confirmButtonColor: '#444444',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translateService.instant('YES'),
      cancelButtonText: this.translateService.instant('NO'),
    }).then((result) => {
      // Si pulsamos borrar en el alert, llamaremos al servicio
      if (result.isConfirmed) {
        // Llamamos al servicio
        let formDataUpdated = new FormData();
        formDataUpdated.append("remove_file", '1');
        formDataUpdated.append("title", firma.title);
        formDataUpdated.append("message", firma.message);
        formDataUpdated.append("public", firma.public);
        this.llamadaServicioEditar(firma.id, formDataUpdated);
      }
    })
  }

  // guardamos el comentario editado
  guardarEdicion(firma: any) {
    let id = firma.id
    let message = (<HTMLInputElement>document.getElementById('textArea' + id)).value;
    let title = (<HTMLInputElement>document.getElementById('title' + id)).value;
    let publico = firma.public;


    if (title !== '' && message !== '') {

      let formDataUpdated = new FormData();
      formDataUpdated.append("title", title);
      formDataUpdated.append("message", message);
      formDataUpdated.append("public", publico);
      this.llamadaServicioEditar(id, formDataUpdated);
    }

  }

  llamadaServicioEditar(comment_id: any, formDataUpdated: any) {
    this.datosClientesService.editarComentarioAutenticado(this.profile_id, this.access_token, comment_id, formDataUpdated).subscribe(
      (data: any) => {
        // tiene exito
        swal.fire({
          title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
          text: this.translateService.instant('THE_MEMORY_HAS_BEEN_MODIFIED_SUCCESSFULLY'),
          icon: "success",
          iconColor: "#444444",
          confirmButtonColor: '#444444',
          confirmButtonText: 'OK',
      })
        setTimeout(function () {
          swal.close();
          location.reload();
        }, 2000);
      },
      // da error
      (error) => {
        console.error(error);
        swal.fire(
          this.translateService.instant('ERROR'),
          this.translateService.instant('ERROR_OCCURRED'),
          'error'
        )
        setTimeout(function () {
          swal.close();
        }, 2000);
      }
    );
  }
  

  /**
   * Llamameos al servicio para poder averiguar el template que tenemos
   * @param web_code 
   */
   obtenerTemplate(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        this.template = "template1";
        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute("href", "assets/css/template1.css");
      },
      (error) => {
        // console.log(error);
      }
    );
  }

    // Page Event
    onChangePagePublic(event:PageEvent){
      this.currentPagePublic = event.pageIndex;
      this.cargarMuroNoAutenticado();
    }

        // Page Event
    onChangePagePrivate(event:PageEvent){
      this.currentPagePrivate = event.pageIndex;
      this.cargarMuroAutenticado();
    }

    escribirComentario(){
      if (this.isShowForm) {
        this.isShowForm = false;
      } else {
        this.isShowForm = true;
        window.scrollTo(0,document.body.scrollHeight);
      }
    }
  ////////////
}
