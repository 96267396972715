import { ChangeDetectorRef, Component, ContentChild, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatosClienteServicioService } from 'src/app/servicios/datos-cliente-servicio.service';
import { OwlCarousel } from "ngx-owl-carousel";
import { QRCodeModule } from 'angularx-qrcode';
import { PaginaPrincipalPublica } from 'src/app/interfaces/interfaces';
import { HelperService } from 'src/app/helpers/helper';
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment"; // add this 1 of 4
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss'],
  animations: [
    trigger('enterAnimation', [

      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(400)
      ]),
      transition(':leave',
        animate(200, style({ opacity: 0 })))
    ])      
  ]
})

export class TvComponent implements OnInit {

  show1: boolean = true;
  show2: boolean = false;
  show3: boolean = false;
  show4: boolean = false;
  show5: boolean = false;

  web_code: string; // en esta variable almacenaremos el web code gracias al cual obtendemos el id del fallecido
  paginaTV: string = "tv";
  dataPaginaTv: any = [];
  // Mostrar pagina
  mostrarPagina: boolean = false;
  // Variables de los clientes
  profile_id: string;
  nombreCliente: string;
  apellidosCliente: string;
  fNacimientoCliente: string;
  fDefuncionCliente: string;
  title_epitaph: string;
  message_epitaph: string;
  ano_nacimiento: string;
  birthdate_show: boolean;
  ano_fallecimiento: string;
  dia_fallecimiento: string;
  imagen: string;
  imagenFondo: string;
  mostrarImagen: boolean;
  // Data firmas
  dataFirmas: any;
  imagenVacia: any;
  // Data velas
  dataVelas: any;
  numeroVelas: any;
  imgVela: string = '/assets/img/vela.png';
  imgVelas: string = '/assets/img/velas.png';

  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  // profile id del cliente, lo recibimos por url y lo almacenamos en el local storage
  role: string; // rol de la persona autentificada
  access_token: string;
  dataPaginaPrincipal: PaginaPrincipalPublica[] = [];

  imagen_wall: string;
  // datos asesor
  nombreAsesor: string;
  apellidoAsesor: string;
  correoElectronicoAsesor: string;
  telefonoAsesor: string;
  mostrarError: boolean = false;
  fechaNacimiento: any;
  fechaFallecimiento: any;

  // variables ceremonia
  datosAgenda: any; // almacenara la previa de la agenda del 1º evento
  tipoCeremonia: string;
  fechaInicioCeremonia: any;
  horaInicioCeremonia: any;
  fechaFinCeremonia: any;
  horaFinCeremonia: any;
  direccionCeremonia: string;
  salaCeremonia: string;
  numeroAsistentes: string = "0";
  tanatorio: string;
  datosAgenda2: any; // almacenara la previa de la agenda del 1º evento
  tipoCeremonia2: string;
  fechaInicioCeremonia2: any;
  horaInicioCeremonia2: any;
  fechaFinCeremonia2: any;
  horaFinCeremonia2: any;
  direccionCeremonia2: string;
  salaCeremonia2: string;
  numeroAsistentes2: string = "0";
  tanatorio2: string;

  // variables muro de firmas
  mostrarCarruselFirmas: boolean = false;
  mostrarUnaFirmaCarrusel: boolean = false;
  numInvitados: any;
  template: string;
  mostrarCarruselVelas: boolean = false;
  dataFullAgenda: any;
  numberDotsComments: number = 0;
  carouselOptionsComentarios: any = {};
  numberDotsAgenda: number = 0;
  carouselOptionsAgenda: any = {};
  numberDotsVelas: number = 0;
  carouselOptionsVelas: any = {};

  // imagenes por defecto para recuerdos multimedia
  recuerdoDefectoImg: string;
  selectedLang: string;

  //declararamos variables de carruseles
  private carruselAgenda: OwlCarousel;
  private carruselRecuerdos: OwlCarousel;
  private carruselLucesEternas: OwlCarousel;
  private carruselMasServicios: OwlCarousel;

  //preparamos los viewChild para que se instancien cuando el elemento exista en el DOM
  @ViewChild ('carruselAgenda', {static: false}) set content0(content0: OwlCarousel) {
    if(content0) { // initially setter gets called with undefined
        this.carruselAgenda = content0;
    }
  }

  @ViewChild ('carruselRecuerdos', {static: false}) set content1(content1: OwlCarousel) {
    if(content1) { // initially setter gets called with undefined
        this.carruselRecuerdos = content1;
    }
  }

  @ViewChild ('carruselLucesEternas', {static: false}) set content2(content2: OwlCarousel) {
    if(content2) { // initially setter gets called with undefined
        this.carruselLucesEternas = content2;
    }
  }

 @ViewChild ('carruselMasServicios', {static: false}) set content3(content3: OwlCarousel) {
  if(content3) { // initially setter gets called with undefined
      this.carruselMasServicios = content3;
  }
  }

  //contador para usarlo en cálculo de tiempo minimo para mostrar slider de recuerdos y velas
  currentCounter: number = 0;
  intervalId: any; // Variable para almacenar el ID del intervalo

  //variables para mínimo tiempo de recuerdos y luces
  minTimeToShowAgenda: number = 0;
  minTimeToShowRecuerdos: number = 0;
  minTimeToShowLuces: number = 0;
  diapositivasCadaNMilisegundos = 7000;
  todosAgendaMostrados = false;
  todosRecuerdosMostrados = false;
  todosLucesMostrados = false;

  constructor(
    private route: ActivatedRoute,
    private datosClientesService: DatosClienteServicioService,
    private router: Router,
    private helperService: HelperService,
    private translateService: TranslateService,
    private changeDetector : ChangeDetectorRef
    ) {
    localStorage.clear();
    this.route.params.subscribe((params) => {
      this.web_code = params['web_code'];
      localStorage.setItem('tv', this.paginaTV);
      // llamamos al servicio
      this.llamadaDatosCliente(this.web_code);

    });
  }

  ngOnInit() {
    let rutaCss: any = document.getElementById("templates-albia");
    rutaCss.setAttribute(
      "href",
      "assets/css/template5.css"
    );

    setInterval(() => {
      //console.log("Interval ejecutado");
      if(!this.show4) this.cargarMuroFirmas();
      if(!this.show5) this.obtenerVelasEncendidas();
    }, 60000);

    //return;   // DESARROLLO IIV
    //this.startCounter();


    //bucle para mostrar contenedores
    setInterval(() => {
      
      if(this.show1 || this.show2){
        this.mostrarContenedores();
      } else if(this.show3){
        this.todosAgendaMostrados = this.checkTimeElapsed(this.minTimeToShowAgenda);
        if(this.todosAgendaMostrados){
          this.mostrarContenedores();
        }
      } else if(this.show4){
        this.todosRecuerdosMostrados = this.checkTimeElapsed(this.minTimeToShowRecuerdos);
        if(this.todosRecuerdosMostrados){
          this.mostrarContenedores();
        }
      } else if(this.show5){
        this.todosLucesMostrados = this.checkTimeElapsed(this.minTimeToShowLuces);
        if(this.todosLucesMostrados){
          this.mostrarContenedores();
        }
      }

    }, 10000);


    /*setInterval(() => {
      if(this.carruselRecuerdos) this.carruselRecuerdos.trigger('refresh.owl.carousel');
      if(this.carruselLucesEternas) this.carruselLucesEternas.trigger('refresh.owl.carousel');
      if(this.carruselMasServicios) this.carruselMasServicios.trigger('refresh.owl.carousel');
    }, 100);*/

  }

  //contador para calcular tiempo que se está mostrando un bloque
  startCounter(): void {
    this.intervalId = setInterval(() => {
      this.currentCounter++;
      //this.checkTimeElapsed(); // Verifica si ha pasado un tiempo determinado
    }, 1000); // Intervalo de 1 segundo
  }

  checkTimeElapsed(time: any) {
    if (this.currentCounter >= time) {
      console.log('se han mostrado todos ');
      this.stopCounter();
      return true;
    }else{
      console.log("no se han mostrado todos ");
      return false;
    }
  }

  stopCounter(): void {
    this.currentCounter = 0;
    clearInterval(this.intervalId); // Detiene el intervalo
  }

  ngDoCheck() {

    this.selectedLang = localStorage.getItem("lang");
    switch(this.selectedLang) { 
      case "es": { 
         this.recuerdoDefectoImg = "/assets/default/recuerdodefectoesp.png";
         break; 
      } 
      case "en": { 
        this.recuerdoDefectoImg = "/assets/default/recuerdodefectoeng.png";
         break; 
      }
      default: { 
        this.recuerdoDefectoImg = "/assets/default/recuerdodefectoesp.png";
        break; 
     } 
   } 
  }


  mostrarContenedores() {

    this.changeDetector.detectChanges();

    if(this.show1){
      this.show1 = false;
      this.show2 = true;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      setTimeout(()=>{
        if(this.carruselAgenda) this.carruselAgenda.trigger('refresh.owl.carousel');
      }, 50);
      return;
    }

    if(this.show2){
      this.show1 = false;
      this.show2 = false;
      this.show3 = true;
      this.show4 = false;
      this.show5 = false;

      //empezamos contador de tiempo de Agenda
      this.startCounter();

      setTimeout(()=>{
        if(this.carruselRecuerdos) this.carruselRecuerdos.trigger('refresh.owl.carousel');
      }, 100);
      return;
    }

    if(this.show3){
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = true;
      this.show5 = false;

      //empezamos contador de tiempo de Recuerdos multimedia
      this.startCounter();

      setTimeout(()=>{
        if(this.carruselLucesEternas) this.carruselLucesEternas.trigger('refresh.owl.carousel');
      }, 200);
      return;
    }

    if(this.show4){
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = true;

      //empezamos contador de tiempo de Luces eternas
      this.startCounter();

      setTimeout(()=>{
        if(this.carruselMasServicios) this.carruselMasServicios.trigger('refresh.owl.carousel');
      }, 100);
      return;
    }

    if(this.show5){
      this.show1 = true;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      return;
    }

  }

  // Llamamda servicio para pedir los datos del cliente
  llamadaDatosCliente(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        this.dataPaginaTv = data.data;
         console.log("datos", this.dataPaginaTv);
        this.rellenarVariables(this.dataPaginaTv);
      },
      (error) => {
        // console.log(error);
        document.location.href = 'http://celebrasuvida.es/';
      }
    );
  }

  // llamada servicio
  llamadaServicio(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        this.dataPaginaPrincipal = data.data;
        this.template = data.data.template;
        // Metemos el template en el set item
        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );

        //console.log("datos", this.dataPaginaPrincipal);
        this.rellenarVariables(this.dataPaginaPrincipal);
        //this.mostrarPagina = true;
      },
      (error) => {
        document.location.href = "http://celebrasuvida.es/";
      }
    );
  }

  // recogemos la data y la insertamos en variables
  rellenarVariables(dataPaginaPrincipal) {
    // datos cliente
    this.profile_id = dataPaginaPrincipal.id;
    localStorage.setItem("profile_id", this.profile_id);
    this.nombreCliente = dataPaginaPrincipal.name;
    this.apellidosCliente = dataPaginaPrincipal.last_name;
    this.fNacimientoCliente = dataPaginaPrincipal.birthday;
    this.fDefuncionCliente = dataPaginaPrincipal.death;
    this.title_epitaph = dataPaginaPrincipal.title_epitaph;
    this.message_epitaph = dataPaginaPrincipal.message_epitaph;
    this.ano_nacimiento = dataPaginaPrincipal.birthday.split("-").shift();
    this.ano_fallecimiento = dataPaginaPrincipal.death.split("-").shift();
    this.dia_fallecimiento = dataPaginaPrincipal.death.substring(8, 10);
    this.fechaNacimiento = dataPaginaPrincipal.birthday;
    this.fechaNacimiento = moment(this.fechaNacimiento).format("DD/MM/YYYY");
    this.fechaFallecimiento = dataPaginaPrincipal.death;
    this.fechaFallecimiento = moment(this.fechaFallecimiento).format(
      "DD/MM/YYYY"
    );
    this.imagen_wall = dataPaginaPrincipal.wall_image;

    this.imagen = dataPaginaPrincipal.photo;
    if (this.imagen) {
      this.mostrarImagen = true;
    }

    this.cargarAgendaCronologica(this.profile_id);
    this.cargarMuroFirmas();
    this.obtenerVelasEncendidas();
  }

  cargarFamiliaresAllegados() {
    if (this.role == "admin") {
      this.datosClientesService
        .cargarListaFamiliaresAllegados(this.access_token, this.profile_id)
        .subscribe(
          (data: any) => {
            this.numInvitados = data.data.length;
            //console.log(data);
          },
          (error) => {
            console.error("error ", error);
          }
        );
    }
  }

  // cargamos la agenda cronologica
  cargarAgendaCronologica(profile_id) {
    this.datosClientesService.cargarAgendaCronologica(profile_id).subscribe(
      (data: any) => {           
         
       this.dataFullAgenda = data.data; 

        // Formatear ISO formato fecha
        this.dataFullAgenda.forEach(function(part, index) {
          if (part.start){ 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');    
          }              
          if (part.end){ 
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');    
          }          
        });

        //this.dataFullAgenda = data.data;  

               
        if (this.dataFullAgenda.length > 0) {
          this.numberDotsAgenda = this.dataFullAgenda.length / 3;
          
          this.minTimeToShowAgenda = (this.dataFullAgenda.length * (5000/1000)) + 3;
          this.cargarCarruselAgenda();
        }
        console.log("minTimeToShowAgenda" ,  this.minTimeToShowAgenda);
      },
      (error) => {
        // console.error(error);
      }
    );
  }

  cargarMuroFirmas() {
    // WE LOAD THE DEFAULT IMAGE IN BASE64  FROM SERVICE
    this.datosClientesService.cargarImagenVacia().subscribe(
      (data: any) => {
        this.imagenVacia = data.data[0].file.path;
      },
      (error) => {}
    );
    // WE LOAD THE INFORMATION ABOUT THE SIGNATURE WALL FROM SERVICE
    this.datosClientesService
      .cargarMuroFirmasSinAutentificacion(this.profile_id)
      .subscribe(
        (data: any) => {
          this.dataFirmas = data.data;
          if (this.dataFirmas.length > 0) {
            this.numberDotsComments = this.dataFirmas.length / 3;
            this.cargarCarruselComentarios();
            this.mostrarCarruselFirmas = true;

            //definimos tiempo minimo para mostrar Recuerdos
            let totalDiapositivasRecuerdos = Math.ceil(this.dataFirmas.length / 1.5);
            this.minTimeToShowRecuerdos = (totalDiapositivasRecuerdos * (this.diapositivasCadaNMilisegundos/1000)) + 40;
          }
          console.log("minTimeToShowRecuerdos" ,  this.minTimeToShowRecuerdos);
        },
        (error) => {}
      );
  }

  obtenerVelasEncendidas() {
    if (localStorage.getItem("role")) {
      this.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.access_token = localStorage.getItem("access_token");
    }

    this.datosClientesService.cargarVelas(this.profile_id).subscribe(
      (data: any) => {
        this.dataVelas = data.data;

        this.numberDotsVelas = this.dataVelas.length / 3;

        if (this.dataVelas.length > 0) {
          this.cargarCarruselVelas();
          this.mostrarCarruselVelas = true;

          //definimos tiempo minimo para mostrar Luces
          let totalDiapositivaLuces = this.dataVelas.length;
          console.log("totalDiapositivaLuces: ", totalDiapositivaLuces);
          this.minTimeToShowLuces = (totalDiapositivaLuces * (this.diapositivasCadaNMilisegundos/1000)) + 15;
        }
        console.log("minTimeToShowLuces" ,  this.minTimeToShowLuces);
        this.mostrarPagina = true;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  cargarCarruselAgenda() {
    this.carouselOptionsAgenda = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      singleItem: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsAgenda,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
        },
        1000: {
          items: 1,
          nav: false,
          loop: true,
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
        },
      },
    };
  }

  cargarCarruselComentarios() {
    this.carouselOptionsComentarios = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: this.diapositivasCadaNMilisegundos,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsComments,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
        },
        1000: {
          items: 2,
          nav: false,
          loop: true,
        },
        1500: {
          items: 2,
          nav: false,
          loop: true,
        },
      },
    };

  }

  cargarCarruselVelas() {
    this.carouselOptionsVelas = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: this.diapositivasCadaNMilisegundos,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsVelas,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
        },
        1000: {
          items: 1,
          nav: false,
          loop: true,
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
        },
      },
    };
  }

}
