import { Component, OnInit } from '@angular/core';
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import { Location } from '@angular/common'
import { HelperService } from "src/app/helpers/helper";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: 'app-content-download',
  templateUrl: './content-download.component.html',
  styleUrls: ['./content-download.component.scss']
})
export class ContentDownloadComponent implements OnInit {

  access_token: string; // almacena el access token
  role: string; // role de la persona autentificada
  profile_id: string; // profile
  mostrarAdmin: boolean = false; // todo lo englobado por esta boleano, solo podra ser visto por administradores
  nombre: string;
  apellidos: string;
  web_code: string;
  data: any;
  url:any;
  respuesta_descarga:any;

  constructor(
    private datosClientesService: DatosClienteServicioService,
    private helperService: HelperService,
    private location: Location,
    private http: HttpClient,
    private translateService: TranslateService

  ) { 
    this.access_token = localStorage.getItem("access_token");
    this.role = localStorage.getItem("role");
    this.profile_id = localStorage.getItem("profile_id");
    this.web_code = localStorage.getItem("web_code"); 
    this.url = environment.api;

    
    
    if (this.role === "admin") {
      this.mostrarAdmin = true;

    }


    if (this.access_token) {
      // console.log("Role autentificado familiares y allegados --> " + this.role);
    } else {
      document.location.href = "http://celebrasuvida.es/";
    }
  }

  ngOnInit() {  
    this.data=this.cargarDataProfile();
   console.log("this.data",this.data);
  }

  descargarContenido(format) {

    let bear_access_token = "Bearer " + this.access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    // console.log('format',format);
    // console.log('this.access_token',this.access_token);
    // console.log('this.profile_id',this.profile_id);
    this.http.get(`${this.url}/profile/${this.profile_id}/download/${format}`, { headers: header }).subscribe(response => {
      this.respuesta_descarga = response;
      swal.fire({
        title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS_DOWNLOAD') + "</h5>",
        text: this.translateService.instant('DOWNLOAD_TEXT_SUCCESS'),
        icon: "success",
        iconColor: "#444444",
        confirmButtonColor: '#444444',
        confirmButtonText: 'OK',
      });
      console.log(this.respuesta_descarga.data);
      
    });

   



    


  }

  cargarDataProfile() {
    // pedimos los datos
    this.datosClientesService.cargarPaginaPrincipal(this.web_code).subscribe(
      (data: any) => {
        // console.log(data);
        this.nombre = data.data.name;
        this.apellidos = data.data.last_name;
        // Metemos el template en el set item
       
      },
      (error) => {
        // si profile_id da error, es que no son correctos y redirigimos fuera de la app
        document.location.href = "http://celebrasuvida.es/";
        console.error(error);
      }
    );
  }

  back(): void {
    this.location.back()
  }
}
