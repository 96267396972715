import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaginaPrincipalPublica } from "src/app/interfaces/interfaces";
import { DatosClienteServicioService } from "../../servicios/datos-cliente-servicio.service";
import * as moment from "moment"; // add this 1 of 4
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import swal from "sweetalert2";
import { PageEvent } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-velas",
  templateUrl: "./velas.component.html",
  styleUrls: ["./velas.component.scss"],
})
export class VelasComponent implements OnInit, AfterViewChecked {
  // profile id del cliente, lo recibimos por url y lo almacenamos en el local storage
  role: string; // rol de la persona autentificada
  access_token: string;
  profile_id: string;
  dataPaginaPrincipal: PaginaPrincipalPublica[] = [];
  // datos cliente
  nombreCliente: string;
  apellidosCliente: string;
  fNacimientoCliente: string;
  fDefuncionCliente: string;
  // datos asesor
  nombreAsesor: string;
  apellidoAsesor: string;
  correoElectronicoAsesor: string;
  telefonoAsesor: string;
  mostrarError: boolean = false;
  mensajeError: string = "";
  tipoError: number;
  mostrarPagina: boolean;
  mostrarImagen: boolean;
  imagen: string; // imagen recibida del profile
  web_code: string; // parametro recibido por url, a través de este parametro podemos obtener el profifle id del cliente
  ano_nacimiento: string;
  ano_fallecimiento: string;
  dia_fallecimiento: string;
  mes_fallecimiento: string;
  // variables ceremonia
  datosAgenda: any; // almacenara la previa de la agenda
  tipoCeremonia: string;
  fechaInicioCeremonia: any;
  horaInicioCeremonia: any;
  fechaFinCeremonia: any;
  horaFinCeremonia: any;
  direccionCeremonia: string;
  salaCeremonia: string;
  // variables muro de firmas
  dataFirmas: any;
  imagenVacia: any;
  velas = [0, 1, 2, 3];
  mostrarEncenderVelaNueva: boolean = false;
  formEncenderVela: FormGroup;
  dataVelas: any;
  numeroVelas: any;
  template: string;
  totalCandles = 0;
  totalPages = 0;
  pageSize = 10;
  currentPage = 0;

  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(
    private route: ActivatedRoute,
    private datosClientesService: DatosClienteServicioService,
    private router: Router,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.template = localStorage.getItem("template");
    this.web_code = localStorage.getItem("web_code");
    //this.obtenerTemplate(this.web_code);
    this.obtenerVelasEncendidas();
    this.crearFormularioEncenderVela();
  }

ngAfterViewChecked() {
   if(this.totalCandles > 0 ){
    const list = document.getElementsByClassName('mat-paginator-range-label');
    list[0].innerHTML = this.translateService.instant('PAGE') + " " +(this.currentPage+1) + " " + this.translateService.instant('OF') + " " + (this.totalPages);   
   }; 

}

  /*
  ENCENDER UNA VELA
  */
  encenderVela() {
    if (this.mostrarEncenderVelaNueva) {
      this.mostrarEncenderVelaNueva = false;
    } else {
      this.mostrarEncenderVelaNueva = true;
    }
  }

  // Crea el formulario de encender vela
  crearFormularioEncenderVela() {
    this.formEncenderVela = this.fb.group({
      message: ["", [Validators.required, Validators.minLength(6), Validators.maxLength(255)]],
      author: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      aceptarTerminos: [false, Validators.requiredTrue],
    });
  }

  // Limpia el formulario encender vela
  limpiarFormularioEncenderVela() {
    this.formEncenderVela.reset({
      message: "",
      author: "",
    });
  }

  // Gestion de errores de encender vela
  get messageNoValido() {
    return (
      this.formEncenderVela.get("message").invalid &&
      this.formEncenderVela.get("message").touched
    );
  }
  get authorNoValido() {
    return (
      this.formEncenderVela.get("author").invalid &&
      this.formEncenderVela.get("author").touched
    );
  }

  get aceptarTerminosNoValido() {
    return (
      this.formEncenderVela.get("aceptarTerminos").invalid &&
      this.formEncenderVela.get("aceptarTerminos").touched
    );
  }

  // comprobamos que el formulario de encender vela no tiene errores
  enviarEncenderVela() {
    // comprobamos que el formulario se ha rellenado correctamente
    if (this.formEncenderVela.invalid) {
      // validaciones
      return Object.values(this.formEncenderVela.controls).forEach(
        (control) => {
          if (control instanceof FormGroup) {
            Object.values(control.controls).forEach((control) =>
              control.markAsTouched()
            );
          } else {
            control.markAsTouched();
          }
        }
      );
    } else {
      // console.log("Formulario correcto");
      this.enviarVelaEncendidaServidor();
    }
  }

  // llamamos al servidor y guardamos las velas encendidas
  enviarVelaEncendidaServidor() {
    let valoresFormulario = this.formEncenderVela.value;
    let valoresFormularioEncenderVela = {
      message: valoresFormulario.message,
      author: valoresFormulario.author,
    };

    this.datosClientesService
      .encenderVelaPublico(
        valoresFormularioEncenderVela,
        localStorage.getItem("profile_id")
      )
      .subscribe(
        (data: any) => {
          // console.log(data);
          this.obtenerVelasEncendidas();
          swal.fire({
            title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
            text: this.translateService.instant('THE_LIGHT_HAS_BEEN_TURNED_ON'),
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
          })
          this.formEncenderVela.reset({
            message: "",
          });
          this.mostrarEncenderVelaNueva = false;
          setTimeout(function () {
            swal.close();
          }, 2000);
        },
        (error) => {
          console.error(error);
          swal.fire(
            this.translateService.instant('ERROR'),
            this.translateService.instant('ERROR_OCCURRED'),
            'error'
          )
          setTimeout(() => {
            swal.close();
          }, 2000);
        }
      );
  }

  /**
   * Velas encendidas
   */
  obtenerVelasEncendidas() {
    if (localStorage.getItem("role")) {
      this.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.access_token = localStorage.getItem("access_token");
    }

      const params = {
        page: this.currentPage + 1,
      }
      this.datosClientesService
      .cargarVelasPaginadas(localStorage.getItem("profile_id"), params)
      .subscribe(
        (data: any) => {
          this.mostrarPagina = true;
          this.dataVelas = data.data.items;
          this.totalCandles = data.data.total;        
          this.pageSize = data.data.per_page;
          this.totalPages = Math.ceil(this.totalCandles/this.pageSize);
          
          window.scrollTo({top: 0, behavior: 'smooth'});   
        },
        (error) => {
          console.error(error);
          swal.fire(
            this.translateService.instant('ERROR'),
            this.translateService.instant('ERROR_OCCURRED'),
            'error'
          )
          setTimeout(() => {
            swal.close();
          }, 2000);
        }
      );
  }

  borrarVela(vela: any) {
    let candle_id = vela.id;
    // sweet alert
    swal
      .fire({
        title: this.translateService.instant('YOU_WANT_TO_DELETE_LIGHT'),
        text: this.translateService.instant('IF_PRESS_YES_LIGHT_WILL_BE_DELETED'),
        icon: "warning",
        showCancelButton: true,
        iconColor: "#444444",
        confirmButtonColor: "#444444",
        cancelButtonColor: "#d33",
        confirmButtonText: this.translateService.instant('YES'),
        cancelButtonText: this.translateService.instant('NO'),
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.datosClientesService
            .borrarVelas(
              localStorage.getItem("profile_id"),
              this.access_token,
              candle_id
            )
            .subscribe(
              (data: any) => {
                this.obtenerVelasEncendidas();
                swal.fire({
                  title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                  text: this.translateService.instant('THE_LIGHT_HAS_BEEN_DELETED'),
                  icon: "success",
                  iconColor: "#444444",
                  confirmButtonColor: '#444444',
                  confirmButtonText: 'OK',
                })
                this.formEncenderVela.reset({
                  message: "",
                });
                this.mostrarEncenderVelaNueva = false;
                setTimeout(function () {
                  swal.close();
                }, 1000);
              },
              (error) => {
                console.error(error);
                swal.fire(
                  this.translateService.instant('ERROR'),
                  this.translateService.instant('ERROR_OCCURRED'),
                  'error'
                )
                setTimeout(() => {
                  swal.close();
                }, 2000);
              }
            );
        }
      });
  }

  /**
   * Llamameos al servicio para poder averiguar el template que tenemos
   * @param web_code
   */
  obtenerTemplate(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        this.template = data.data.template;
        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );
      },
      (error) => {
        console.error(error);
        swal.fire(
          this.translateService.instant('ERROR'),
          this.translateService.instant('ERROR_OCCURRED'),
          'error'
        )
        setTimeout(() => {
          swal.close();
        }, 2000);
      }
    );
  }

  // Page Event
  onChangePage(event:PageEvent){
    this.currentPage = event.pageIndex;
    this.obtenerVelasEncendidas();
  }
  //////
}
