import { Component, DoCheck, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from "src/app/helpers/helper";
import * as moment from "moment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})

export class NavbarComponent implements OnInit, DoCheck {
  web_code: string;
  profile_id: string;
  role: string;
  mostrarPanelAdministracion: boolean;
  mostrarZonaPublica: boolean;
  mostrarLogin: boolean = true;
  access_token: string; // almacena el access token
  token: string; // almacena el token recibido por URL
  tv: string; // almacena la variable donde comprobaremos si lo estamos visualizando en una tv
  show_logo: string;

  elementosPendientesModeracion: number = 0;
  leerComentarios: boolean = true;
  template: string;

  hasStreaming: any = false;
  dataFullAgenda: any;

  color = "#0F5D75";


  floristeria_custom: { found: boolean, url?: string } = { found: false };

  constructor(
    private router: Router,
    private datosClientesService: DatosClienteServicioService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private helperService: HelperService
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.mostrarPanelAdministracion = false;
      this.web_code = localStorage.getItem("web_code");
      if (this.web_code) {
        this.obtenerTemplate(this.web_code);
      }

      this.template = localStorage.getItem("template");
      this.profile_id = localStorage.getItem("profile_id");
      this.access_token = localStorage.getItem("access_token");

      setInterval(() => {
        this.helperService.updateQuantityNotificaciones();
        this.elementosPendientesModeracion = parseInt(localStorage.getItem('elementosPendientesModeracion'));
      }, 3000);

      setTimeout(() => {
        this.cargarAgendaCronologica(this.profile_id);
      }, 1000);
    }, 1000);

  }

  scrolled = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 50;
  }

  ngDoCheck() {
    this.web_code = localStorage.getItem("web_code");
    this.profile_id = localStorage.getItem("profile_id");
    this.access_token = localStorage.getItem("access_token");
    this.token = localStorage.getItem("token");
    this.role = localStorage.getItem("role");
    this.tv = localStorage.getItem("tv");

    if (this.web_code === "exit") {
      this.mostrarZonaPublica = false;
    } else {
      this.mostrarZonaPublica = true;
    }

    if (this.access_token) {
      this.mostrarPanelAdministracion = true;
    } else {
      this.mostrarPanelAdministracion = false;
    }

    if (this.profile_id) {
      this.mostrarZonaPublica = true;
    } else {
      this.mostrarZonaPublica = false;
    }

    if (this.role) {
      this.mostrarLogin = false;
    } else {
      this.mostrarLogin = true;
    }

    if (this.tv === "tv") {
      this.mostrarZonaPublica = false;
    }
  }

  cargarAgendaCronologica(profile_id) {

    this.datosClientesService.cargarAgendaCronologica(profile_id).subscribe(
      (data: any) => {           
         
       this.dataFullAgenda = data.data; 
  
        // Formatear ISO formato fecha
        this.dataFullAgenda.forEach(function(part, index) {
          if (part.start) { 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');
          } 
  
          if (part.end) {
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');   
          }
        });
  
        for (let part of this.dataFullAgenda) {
          if (part.streaming == 1){
            //part.start = part.start.replace(' ', 'T');
            this.hasStreaming = part.start;
          }
        }
      },
      (error) => {
        // console.error(error);
      }
    );
  }

  selectLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem("lang", lang);
  }

  getCurrentLanguage(){
    return this.translateService.currentLang;
  }

  cerrarSesion() {
    localStorage.clear();
    document.location.href =
      "https://csv-demo.informatica-integral.com/home/" + this.web_code;
  }

  // cerramos el menu en dispositivos moviles
  esconderMenu() {
    const element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
    let navButton = document.getElementById("navButton");
    navButton.classList.add("collapsed");
  }

  /**
   * Llamameos al servicio para poder averiguar el template que tenemos
   * @param web_code
   */
  obtenerTemplate(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        this.template = data.data.template;
        this.show_logo = data.data.office.show_corp_logo;
          console.log("show_logo", data.data.office.show_corp_logo);
          localStorage.setItem("logo", this.show_logo);

        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );


        let dataPaginaPrincipal = data.data;

        this.floristeria_custom = dataPaginaPrincipal.services.reduce((result, service) => {
          if (service.category === 'florist') {
            result.found = true;
            result.url = service.url;
          }
          return result;
        }, this.floristeria_custom);

      },
      (error) => {
        // console.log(error);
      }
    );
  }

  ////
}