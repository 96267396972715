import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaginaPrincipalPublica } from "src/app/interfaces/interfaces";
import { DatosClienteServicioService } from "../../servicios/datos-cliente-servicio.service";
import * as moment from "moment"; // add this 1 of 4
import { OwlCarousel } from "ngx-owl-carousel";
import { HelperService } from "src/app/helpers/helper";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})

export class HomeComponent implements OnInit{

  // profile id del cliente, lo recibimos por url y lo almacenamos en el local storage
  role: string; // rol de la persona autentificada
  access_token: string;
  profile_id: string;
  dataPaginaPrincipal: any;
  // datos cliente
  nombreCliente: string;
  apellidosCliente: string;
  fNacimientoCliente: string;
  fDefuncionCliente: string;
  title_epitaph: string;
  message_epitaph: string;
  imagen_wall: string;
  birthdate_show: any;
  officeHasStreaming: any
  // datos asesor
  // nombreAsesor: string;
  // apellidoAsesor: string;
  // correoElectronicoAsesor: string;
  // telefonoAsesor: string;
  mostrarError: boolean = false;
  mostrarPagina: boolean;
  mostrarImagen: boolean;
  imagen: string; // imagen recibida del profile
  web_code: string; // parametro recibido por url, a través de este parametro podemos obtener el profifle id del cliente
  ano_nacimiento: string;
  ano_fallecimiento: string;
  dia_fallecimiento: string;
  mes_fallecimiento: string;
  fechaNacimiento: any;
  fechaFallecimiento: any;

  // variables ceremonia
  datosAgenda: any; // almacenara la previa de la agenda del 1º evento
  tipoCeremonia: string;
  fechaInicioCeremonia: any;
  horaInicioCeremonia: any;
  fechaFinCeremonia: any;
  horaFinCeremonia: any;
  direccionCeremonia: string;
  salaCeremonia: string;
  numeroAsistentes: string = "0";
  tanatorio: string;
  datosAgenda2: any; // almacenara la previa de la agenda del 1º evento
  tipoCeremonia2: string;
  fechaInicioCeremonia2: any;
  horaInicioCeremonia2: any;
  fechaFinCeremonia2: any;
  horaFinCeremonia2: any;
  direccionCeremonia2: string;
  salaCeremonia2: string;
  numeroAsistentes2: string = "0";
  tanatorio2: string;
  copiarEnlaceHome:any;
  // variables muro de firmas
  dataFirmas: any;
  mostrarCarruselFirmas: boolean = false;
  mostrarUnaFirmaCarrusel: boolean = false;
  imagenVacia: any;
  dataVelas: any;
  numInvitados: any;
  template: string;
  mostrarCarruselVelas: boolean = false;
  dataFullAgenda: any;
  numberDotsComments: number = 0;
  carouselOptionsComentarios: any = {};
  numberDotsAgenda: number = 0;
  carouselOptionsAgenda: any = {};
  numberDotsVelas: number = 0;
  carouselOptionsVelas: any = {};
  hasStreaming: any = false;
  show_logo: string;

  // imagenes por defecto para recuerdos multimedia
  recuerdoDefectoImg: string;
  selectedLang: string;

  datosCarruselMasServicios: any;
  datosCarruselMasServiciosCustom: any;
  floristeria_custom: { found: boolean, url?: string } = { found: false };
  floristeria_service: any;

  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)"

  //añadimos viewchild para acceder a las funciones del elemento owlcarrousel
  @ViewChild('carruselAgenda', {static: false}) carruselAgenda : OwlCarousel
  @ViewChild('carruselRecuerdos', {static: false}) carruselRecuerdos : OwlCarousel
  @ViewChild('carruselLucesEternas', {static: false}) carruselLucesEternas : OwlCarousel
  @ViewChild('carruselMasServicios', {static: false}) carruselMasServicios : OwlCarousel
  
  constructor(
    private route: ActivatedRoute,
    private datosClientesService: DatosClienteServicioService,
    private router: Router,
    private helperService: HelperService,
    private translateService: TranslateService
    )
  {
   
    localStorage.setItem("tv", "");
    // recogemos el id  y lo almacenamos en el local storage
    this.route.params.subscribe((params) => {
      this.web_code = params["web_code"];

      // Borramos el local storage si el web code del locals torage es diferente del params
      if (this.web_code != localStorage.getItem("web_code")) {
        localStorage.clear();
      }
      //
      // nos aseguramos de vaciar el local storage por si hubiese algo
      localStorage.setItem("web_code", this.web_code);
      // llamamos al servicio
      this.llamadaServicio(this.web_code);
      this.profile_id = localStorage.getItem("profile_id");
      this.access_token = localStorage.getItem("access_token");
      this.role = localStorage.getItem("role");

      if (this.role === "admin") {
        this.cargarFamiliaresAllegados();
      }
    });
  }
  
  ngDoCheck() {

    this.selectedLang = localStorage.getItem("lang");
    
    switch(this.selectedLang) { 
      case "es": {
        if(this.show_logo == "1"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoesp.png";
          break; 
        }
        if(this.show_logo == "0"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoespNoAlbia.png";
          break; 
        }
      } 
      case "en": { 
        if(this.show_logo == "1"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoeng.png";
          break; 
        }
        if(this.show_logo == "0"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoengNoAlbia.png";
          break; 
        } 
      }
      default: { 
        if(this.show_logo == "1"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoesp.png";
          break; 
        }
        if(this.show_logo == "0"){
          this.recuerdoDefectoImg = "/assets/default/recuerdodefectoespNoAlbia.png";
          break; 
        }
     } 
   } 
  }

  ngOnInit() {

      setTimeout(()=>{
        if(this.carruselRecuerdos) this.carruselRecuerdos.trigger('refresh.owl.carousel');
        if(this.carruselLucesEternas) this.carruselLucesEternas.trigger('refresh.owl.carousel');
        if(this.carruselMasServicios) this.carruselMasServicios.trigger('refresh.owl.carousel');
    }, 1000);
    this.show_logo = this.datosClientesService.show_logo();
    console.warn("SHOW_LOGO", this.show_logo);
  }

  copiarEnlace() {

    //produccion
    this.copiarEnlaceHome = "https://csv-demo.informatica-integral.com/invitation?profile=" + this.web_code;

    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copiarEnlaceHome;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  
  
    swal.fire({
      title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
      text: this.translateService.instant('LINK_COPIED_SUCCESSFULLY'),
      icon: "success",
      iconColor: "#444444",
      confirmButtonColor: '#444444',
      confirmButtonText: 'OK',
    });
    //  console.log(data);
    setTimeout(function () {
      swal.close();
    }, 3000);
  }

  // llamada servicio
  llamadaServicio(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
        console.warn(data);
        this.dataPaginaPrincipal = data.data;
        this.template = data.data.template;
        this.show_logo = data.data.office.show_corp_logo;
          localStorage.setItem("logo", this.show_logo);
        // Metemos el template en el set item
        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );

        //console.log("datos", this.dataPaginaPrincipal);
        this.rellenarVariables(this.dataPaginaPrincipal);
        //this.mostrarPagina = true;
      },
      (error) => {
        document.location.href = "http://celebrasuvida.es/";
      }
    );
  }

  // recogemos la data y la insertamos en variables
  rellenarVariables(dataPaginaPrincipal) {
    // datos cliente
    this.profile_id = dataPaginaPrincipal.id;
    localStorage.setItem("profile_id", this.profile_id);
    this.nombreCliente = dataPaginaPrincipal.name;
    this.birthdate_show = dataPaginaPrincipal.birthdate_show;
    this.officeHasStreaming = dataPaginaPrincipal.office.has_streaming;

    this.apellidosCliente = dataPaginaPrincipal.last_name;
    this.fNacimientoCliente = dataPaginaPrincipal.birthday;
    this.fDefuncionCliente = dataPaginaPrincipal.death;
    this.title_epitaph = dataPaginaPrincipal.title_epitaph;
    this.message_epitaph = dataPaginaPrincipal.message_epitaph;
    this.ano_nacimiento = dataPaginaPrincipal.birthday.split("-").shift();
    this.ano_fallecimiento = dataPaginaPrincipal.death.split("-").shift();
    this.dia_fallecimiento = dataPaginaPrincipal.death.substring(8, 10);
    this.fechaNacimiento = dataPaginaPrincipal.birthday;
    this.fechaNacimiento = moment(this.fechaNacimiento).format("DD/MM/YYYY");
    this.fechaFallecimiento = dataPaginaPrincipal.death;
    this.fechaFallecimiento = moment(this.fechaFallecimiento).format("DD/MM/YYYY");
    this.imagen_wall = dataPaginaPrincipal.wall_image;

    this.imagen = dataPaginaPrincipal.photo;
    if (this.imagen) {
      this.mostrarImagen = true;
    }
    // data asesor
    // this.nombreAsesor = dataPaginaPrincipal.adviser.name;
    // this.apellidoAsesor = dataPaginaPrincipal.adviser.last_name;
    // this.correoElectronicoAsesor = dataPaginaPrincipal.adviser.email;
    // this.telefonoAsesor = dataPaginaPrincipal.adviser.phone;

    //check if get custom florist service
 
    this.floristeria_custom = this.dataPaginaPrincipal.services.reduce((result, service) => {
      if (service.category === 'florist') {
        result.found = true;
        result.url = service.url;
      }
      return result;
    }, this.floristeria_custom);

    // add flower service /custom/default
    if(this.floristeria_custom.found){
      this.floristeria_service = {
          image: "/assets/img/flores_custom.png",
          type: "image",
          name: "MORE_SERVICES_1_NAME",
          title: "MORE_SERVICES_1_TITLE",
          text1: "CUSTOM_TEXT_FLORIST",
          text2: "",
          button: this.floristeria_custom.url,
          fixed: true
        };
      
      } else {
        this.floristeria_service = {
          image: "/assets/img/esencia.jpg",
          type: "image",
          name: "MORE_SERVICES_1_NAME",
          title: "MORE_SERVICES_1_TITLE",
          text1: "MORE_SERVICES_1_TEXT1",
          text2:"MORE_SERVICES_1_TEXT2",
          button: "MORE_SERVICES_1_BUTTON",
          fixed: true
        };

      }

      let servicesWOFlorist = this.dataPaginaPrincipal.services.filter(item => item.category !== "florist");
      this.createDatosCarrouselMasServicios(this.floristeria_service, servicesWOFlorist);
  
    this.cargarAgendaCronologica(this.profile_id);
    this.cargarMuroFirmas();
    this.obtenerVelasEncendidas();
  }

  cargarFamiliaresAllegados() {
    if (this.role == "admin") {
      this.datosClientesService
        .cargarListaFamiliaresAllegados(this.access_token, this.profile_id)
        .subscribe(
          (data: any) => {
            this.numInvitados = data.data.length;
            //console.log(data);
          },
          (error) => {
            console.error("error ", error);
          }
        );
    }
  }

  /*
    navegamos a la pantalla de eventos
  */
  navegarEventos() {
    this.router.navigateByUrl("/agendaCronologicaPublica");
  }

  /*
    navegamos a la pantalla de velas
  */
  navegarVelas() {
    this.router.navigateByUrl("/velas");
  }

  /*
    navegamos al muro de firmas
  */
  navegarMuroFirmas() {
    this.router.navigateByUrl("/muroFirmas");
  }

  /* 
    navegamos a la ceremonia online
  */
  navegarCeremoniaOnline() {
    this.router.navigateByUrl("/ceremoniaOnline");
  }

  /*
      navegamos a más servicios 
  */
  navegarMasServicios() {
    this.router.navigateByUrl("/masServicios");
  }

  /*
  CARGAMOS UNA PREVIA DE LA AGENDA CRONOLOGIA
  */
  // cargamos la agenda cronologica
  cargarAgendaCronologica(profile_id) {
    this.datosClientesService.cargarAgendaCronologica(profile_id).subscribe(
      (data: any) => {           
         
       this.dataFullAgenda = data.data; 

       console.warn(this.dataFullAgenda);

        // Formatear ISO formato fecha
        this.dataFullAgenda.forEach(function(part, index) {
          if (part.start) { 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');
          } 

          if (part.end) {
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');   
          }
        });

        if (this.dataFullAgenda.length > 0) {
          this.numberDotsAgenda = this.dataFullAgenda.length / 3;
          this.cargarCarruselAgenda();
        }
        for (let part of this.dataFullAgenda) {
          if (part.streaming == 1){
            //part.start = part.start.replace(' ', 'T');
            this.hasStreaming = part.start;
          }
        }
      },
      (error) => {
        // console.error(error);
      }
    );
  }

  /*
  MURO DE FIRMAS EN EL HOME
  */
  cargarMuroFirmas() {
    // WE LOAD THE DEFAULT IMAGE IN BASE64  FROM SERVICE
    this.datosClientesService.cargarImagenVacia().subscribe(
      (data: any) => {
        this.imagenVacia = data.data[0].file.path;
      },
      (error) => {}
    );
    // WE LOAD THE INFORMATION ABOUT THE SIGNATURE WALL FROM SERVICE
    this.datosClientesService
      .cargarMuroFirmasSinAutentificacion(this.profile_id)
      .subscribe(
        (data: any) => {
          this.dataFirmas = data.data;
          console.warn(this.dataFirmas);
          // Formatear ISO formato fecha
          this.dataFirmas.forEach(function(part, index) {
            if (part.created_at) { 
              part.created_at = part.created_at.replace(' ', 'T');
            } 
          });
          if (this.dataFirmas.length > 0) {
            this.numberDotsComments = this.dataFirmas.length / 3;
            this.cargarCarruselComentarios();
            this.mostrarCarruselFirmas = true;
          }
        },
        (error) => {}
      );
  }
  /**
   * Velas encendidas
   */
  obtenerVelasEncendidas() {
    if (localStorage.getItem("role")) {
      this.role = localStorage.getItem("role");
    }
    if (localStorage.getItem("access_token")) {
      this.access_token = localStorage.getItem("access_token");
    }

    this.datosClientesService.cargarVelas(this.profile_id).subscribe(
      (data: any) => {
        this.dataVelas = data.data;

        this.numberDotsVelas = this.dataVelas.length / 3;

        if (this.dataVelas.length > 0) {
          this.cargarCarruselVelas();
          this.mostrarCarruselVelas = true;
        }
        this.mostrarPagina = true;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  /*
  INICIO CARRUSEL MÁS SERVICIOS
  */
  // Objeto opciobes del OWL carrusel
  carouselOptionsMasServicios = {
    margin: 0,
    nav: false,
    navText: [],
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    dots: false,
    autoHeight: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
        loop: true,
        'smartSpeed': 0
      },
      600: {
        items: 1,
        nav: false,
        loop: true,
        'smartSpeed': 0
      },
      1000: {
        items: 1,
        nav: false,
        loop: true,
      },
      1500: {
        items: 1,
        nav: false,
        loop: true,
      },
    },
  };
  // datos del carrusel más servicios

  createDatosCarrouselMasServicios(servicioFlores, servicesWOFlorist) {

    this.datosCarruselMasServicios = [
      {
        image: "/assets/video/atencion.mp4",
        type: "video",
        poster: "/assets/video/preview.webp",
        name: "MORE_SERVICES_2_NAME",
        title: "MORE_SERVICES_2_TITLE",
        text1:
          "MORE_SERVICES_2_TEXT1",
        text2:
          "MORE_SERVICES_2_TEXT2",
        button: "MORE_SERVICES_2_BUTTON",
        fixed: true
      },
      {
        image: "/assets/img/slider1.jpg",
        type: "image",
        name: "MORE_SERVICES_3_NAME",
        title: "MORE_SERVICES_3_TITLE",
        text1:
          "MORE_SERVICES_3_TEXT1",
        text2:
          "MORE_SERVICES_3_TEXT2",
        button: "MORE_SERVICES_3_BUTTON",
        fixed: true
      },
    ];

    this.datosCarruselMasServicios.push(servicioFlores);
    this.datosCarruselMasServiciosCustom = servicesWOFlorist;
    // IIV: Los juntamos en un mismo array para simplificar la implementación
    this.datosCarruselMasServicios = this.datosCarruselMasServicios.concat(this.datosCarruselMasServiciosCustom);
  }

  siguienteCarruselServicios(){
    this.carruselMasServicios.next([0]);
  }

  anteriorCarruselServicios(){
    this.carruselMasServicios.previous([0]);
  }


  cargarCarruselAgenda() {

    this.carouselOptionsAgenda = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      singleItem:true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsAgenda,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        1000: {
          items: 1,
          nav: false,
          loop: true,
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
        },
      },
    };
  }

  siguienteCarruselAgenda(){
    this.carruselAgenda.next([0]);
  }

  anteriorCarruselAgenda(){
    this.carruselAgenda.previous([0]);
  }

  cargarCarruselComentarios() {
    this.carouselOptionsComentarios = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsComments,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        1000: {
          items: 1,
          nav: false,
          loop: true,
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
        },
      },
    };
  }

  siguienteCarruselComentarios(){
    this.carruselRecuerdos.next([0]);
  }

  anteriorCarruselComentarios(){
    this.carruselRecuerdos.previous([0]);
  }

  cargarCarruselVelas() {
    this.carouselOptionsVelas = {
      margin: 0,
      nav: false,
      navText: [],
      responsiveClass: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      dots: false,
      autoHeight: true,
      dotsEach: this.numberDotsVelas,
      responsive: {
        0: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        600: {
          items: 1,
          nav: false,
          loop: true,
          'smartSpeed': 0
        },
        1000: {
          items: 1,
          nav: false,
          loop: true,
        },
        1500: {
          items: 1,
          nav: false,
          loop: true,
        },
      },
    };
  }

  siguienteCarruselVelas(){
    this.carruselLucesEternas.next([0]);
  }

  anteriorCarruselVelas(){
    this.carruselLucesEternas.previous([0]);
  }

}