import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";


@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss']
})
export class TarjetaComponent implements OnInit {

  formEncuesta: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) { 

   

  }

  ngOnInit() {
  }

}