import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(rutaImagen: any, ...args: any[]): any {

    // console.log(rutaImagen);
    // console.log(args);

    if (rutaImagen) {
      return rutaImagen;
    } else {
      return '/assets/img/imagenBlanco.jpg';
    }

  }

}
