import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./components/home/home.component";
import { FooterComponent } from "./components/shared/footer/footer.component";
import { NavbarComponent } from "./components/shared/navbar/navbar.component";
import { AgendaCronologicaPublicaComponent } from "./components/agenda-cronologica-publica/agenda-cronologica-publica.component";
import { MuroFirmasComponent } from "./components/muro-firmas/muro-firmas.component";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { AdminComponent } from "./components/admin/admin.component";
import { FamiliaresAllegadosComponent } from "./components/familiares-allegados/familiares-allegados.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { InvitationComponent } from "./components/invitation/invitation.component";
import { EditarProfileComponent } from "./components/editar-profile/editar-profile.component";
import { SaveEditarProfileComponent } from "./components/save-editar-profile/save-editar-profile.component";
import { MesPipe } from "./pipes/mes.pipe";
import { DiaSemanaPipe } from "./pipes/dia-semana.pipe";
import { ImagenPipe } from "./pipes/imagen.pipe";
import { ModificarTextosPipe } from "./pipes/modificar-textos.pipe";
import { LoginComponent } from "./components/login/login.component";
import { DomSeguroPipe } from "./pipes/dom-seguro.pipe";
import { VelasComponent } from "./components/velas/velas.component";
import { TvComponent } from "./components/tv/tv.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from "@angular/material/paginator";
import { PaginatorIntlService } from "src/app/servicios/paginator/paginator";
import { MasServiciosComponent } from './components/mas-servicios/mas-servicios.component';
import { CeremoniaOnlineComponent } from './components/ceremonia-online/ceremonia-online.component';
import { OwlModule } from 'ngx-owl-carousel';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomenajesPostumosComponent } from "./components/homenajes-postumos/homenajes-postumos.component";
import { QRCodeModule } from 'angularx-qrcode';
import { ContentDownloadComponent } from './components/content-download/content-download.component';
import { EncuestaComponent } from './components/encuesta/encuesta.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { YoutubePipe } from "./pipes/youtube/youtube.pipe";
import { TarjetaComponent } from "./components/tarjeta/tarjeta.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavbarComponent,
    AgendaCronologicaPublicaComponent,
    MuroFirmasComponent,
    AdminComponent,
    FamiliaresAllegadosComponent,
    InvitationComponent,
    EditarProfileComponent,
    SaveEditarProfileComponent,
    MesPipe,
    DiaSemanaPipe,
    ImagenPipe,
    ModificarTextosPipe,
    LoginComponent,
    DomSeguroPipe,
    VelasComponent,
    TvComponent,
    MasServiciosComponent,
    CeremoniaOnlineComponent,
    HomenajesPostumosComponent,
    ContentDownloadComponent,
    EncuestaComponent,
    PdfComponent,
    YoutubePipe,
    TarjetaComponent
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    OwlModule,
    ImageCropperModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: () => {
        const service = new PaginatorIntlService();
        return service;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}