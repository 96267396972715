import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'mes'
})
export class MesPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ){}
  mesNumerico: string;
  mesString: string;

  transform(fechaCompleta: any): any {

    this.mesNumerico = fechaCompleta.substring(5, 7);
    
    // OBTENEMOS EL MES
    switch (this.mesNumerico) {
      case '01':
        this.mesString = this.translateService.instant('JANUARY');
        break;
      case '02':
        this.mesString = this.translateService.instant('FEBRUARY');
        break;
      case '03':
        this.mesString = this.translateService.instant('MARCH');
        break;
      case '04':
        this.mesString = this.translateService.instant('APRIL');
        break;
      case '05':
        this.mesString = this.translateService.instant('MAY');
        break;
      case '06':
        this.mesString = this.translateService.instant('JUNE');
        break;
      case '07':
        this.mesString = this.translateService.instant('JULY');
        break;
      case '08':
        this.mesString = this.translateService.instant('AUGUST');
        break;
      case '09':
        this.mesString = this.translateService.instant('SEPTEMBER');
        break;
      case '10':
        this.mesString = this.translateService.instant('OCTOBER');
        break;
      case '11':
        this.mesString = this.translateService.instant('NOVEMBER');
        break;
      case '12':
        this.mesString = this.translateService.instant('DECEMBER');
        break;
    }
    
    return this.mesString;

  }

}
