import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from 'src/app/helpers/helper';
import { AgendaCronologicaPublica, PaginaPrincipalPublica } from 'src/app/interfaces/interfaces';
import { DatosClienteServicioService } from 'src/app/servicios/datos-cliente-servicio.service';
import { Router } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: 'app-ceremonia-online',
  templateUrl: './ceremonia-online.component.html',
  styleUrls: ['./ceremonia-online.component.scss']
})

export class CeremoniaOnlineComponent implements OnInit {

  datosAgenda: AgendaCronologicaPublica[] = [];
  datosPrincipales: any;
  profile_id: string;
  web_code: string;
  mostrarPagina: boolean;
  hasStreaming: any = false;
  hasVideo: any = false;
  nombreCliente;
  apellidosCliente;
  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";
  
  constructor(
    private datosClientesService: DatosClienteServicioService,
    private helperService: HelperService,
    private translateService: TranslateService,
    private router: Router
  ) {

    // recogemos el idCliente del local Storage
    this.profile_id = localStorage.getItem('profile_id');

    // si existe el id que recibimos por url, llamamos al servicio
    if (this.profile_id) {
      // llamamos al servicio
      this.cargarAgendaCronologica(this.profile_id);
      this.web_code = localStorage.getItem("web_code");
      this.cargarDatos(this.web_code);
      
    } else {
      // si no existe local storage, redireccionamos y lo sacamos de la web
      document.location.href = 'http://celebrasuvida.es/';
    }

  }

  ngOnInit() {

  }

  viewSurvey() {
    console.log("this.profile_id: " , this.profile_id);
    this.router.navigateByUrl("/encuesta");
  }

  cargarDatos(profile_id) {
    this.datosClientesService.cargarPaginaPrincipal(profile_id).subscribe(
      (data: any) => {
        this.datosPrincipales = data.data;
        this.nombreCliente = this.datosPrincipales.name;
        this.apellidosCliente = this.datosPrincipales.last_name;
      },
      (error) => {
        console.log(error)
      }
    );
  }

  // cargamos la agenda cronologica
  cargarAgendaCronologica(profile_id) {

    this.datosClientesService.cargarAgendaCronologica(profile_id).subscribe(

      (data: any) => {

        this.datosAgenda = data.data;

        // Formatear ISO formato fecha
        this.datosAgenda.forEach(function(part: any, index) {
          if (part.start) { 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');
          }
          if (part.end) {
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');   
          }
        });
    
        for (let part of this.datosAgenda) {

          if (part.streaming == 1) {

            this.hasStreaming = part.start;

            if (part.video != null) {
              this.hasVideo = part.video;
            }
  
            if(part.streaming == 1){
              break;
            }
            
          }
        
        }

        if(this.hasVideo == false) {
          this.router.navigateByUrl("/home/" + this.web_code); 
        }

        this.mostrarPagina = true;
      },
      (error) => {

      }
    );
  }

  noVideoStreaming() {
    this.hasVideo = false;
  }

}
