import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatosClienteServicioService } from "../../servicios/datos-cliente-servicio.service";
import { TranslateService } from "@ngx-translate/core";
import swal from "sweetalert2";


@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {

  formEncuesta: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private datosClientesService: DatosClienteServicioService,
    private translateService: TranslateService
  ) { 

   

  }

  ngOnInit() {
    this.createFrom();
    console.log("eee:" , localStorage.getItem("profile_id"));
    
  }

  createFrom() {

    this.formEncuesta = this.formBuilder.group({
      confort: ['', Validators.required],
      proximidad: ['', Validators.required],
      utilidad: ['', Validators.required],
      comentarios: [''],
      
    });
  }

  onSubmit() {  

    let valoresFormulario = this.formEncuesta.value;
    console.log(valoresFormulario);    
    
    this.datosClientesService.enviarEncuesta(localStorage.getItem("profile_id"), localStorage.getItem("access_token"),valoresFormulario)
      .subscribe(
        (data: any) => {
          swal.fire({
            title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
            text: this.translateService.instant('DATA_SAVED_SUCCESSFULLY'),
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: "#444444",
            confirmButtonText: "OK",
          });
          setTimeout(() => {
            swal.close();
          }, 2000);
        },
        (error) => {
          swal.fire({
            title: "Se ha producido un error",
            text: error.error.message,
            icon: "error",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
          });
          setTimeout(function () {
            swal.close();
          }, 3000);
          
        }
      );    
  }
}