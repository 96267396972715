import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer  } from '@angular/platform-browser';

@Pipe({
  name: 'youtube'
})

export class YoutubePipe implements PipeTransform {

  constructor( private domSanitizer:DomSanitizer ){ }
  
  transform(value: string): any {
    const url = `https://www.youtube.com/embed/`;
    // console.log(url + value);
    return this.domSanitizer.bypassSecurityTrustResourceUrl( url + value);
  }

}
