import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import { Router } from "@angular/router";
import swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  formLoginPublico: FormGroup;
  mostrarLoading: boolean = true;
  mostrarLoadingLogin: boolean = false;
  mostrarPagina: boolean = false;
  mostrarLoginPublico: boolean = false;
  errorLogin: string;
  errorLoginPublico: boolean = false;
  exitoLoginPublico: boolean = false;
  access_token: string;
  profile_id: string;
  role: string;
  dataUsuarioLogeado: any;
  formRecuperarContrasena: FormGroup;
  errorRecuperarContrasena: boolean = false;
  exitoRecuperarContrasena: boolean = false;
  mostrarRecuperarContrasena: boolean = false;
  recoveryPasswordError: any = {};
  mostrarLogin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private datosClientesService: DatosClienteServicioService,
    private router: Router
  ) {}

  ngOnInit() {
    this.crearFormularioLoginPublico();
    this.crearFormularioRecuperarContrasena();
    this.mostrarLoading = false;
    this.mostrarPagina = true;
    this.profile_id = localStorage.getItem("profile_id");
  }

  // Crea el formulario login
  crearFormularioLoginPublico() {
    this.formLoginPublico = this.fb.group({
      correoLoginPublico: ["", [Validators.required, Validators.email]],
      passwordLoginPublico: [
        "",
        [Validators.required, Validators.minLength(8)],
      ],
    });
  }

  // Limpia el formulario login
  limpiarFormularioLoginPublico() {
    this.formLoginPublico.reset({
      correoLoginPublico: "",
      passwordLoginPublico: "",
    });
  }

  // Gestion de errores del login
  get correoLoginPublicoNoValido() {
    return (
      this.formLoginPublico.get("correoLoginPublico").invalid &&
      this.formLoginPublico.get("correoLoginPublico").touched
    );
  }
  get passwordLoginPublicoNoValido() {
    return (
      this.formLoginPublico.get("passwordLoginPublico").invalid &&
      this.formLoginPublico.get("passwordLoginPublico").touched
    );
  }

  // Funcion que getsiona el login
  loginUsuarioPublico() {
    // comprobamos que el formulario se ha rellenado correctamente
    if (this.formLoginPublico.invalid) {
      // validaciones
      return Object.values(this.formLoginPublico.controls).forEach(
        (control) => {
          if (control instanceof FormGroup) {
            Object.values(control.controls).forEach((control) =>
              control.markAsTouched()
            );
          } else {
            control.markAsTouched();
          }
        }
      );
    } else {
      // console.log("Formulario correcto");
      this.llamadaLogin();
    }
  }

  // llamamos al login con password y contraseña, y obtenemos el token access
  llamadaLogin() {
    swal.showLoading();
    let valoresFormularioLoginPublico = this.formLoginPublico.value;
    let valoresFormularioLoginPublicoEnviar = {
      email: valoresFormularioLoginPublico.correoLoginPublico,
      password: valoresFormularioLoginPublico.passwordLoginPublico,
    };
    this.datosClientesService
      .login(valoresFormularioLoginPublicoEnviar)
      .subscribe(
        (data: any) => {
          swal.fire({
            title: "Éxito",
            text: "Se ha iniciado sesión correctamente",
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
          });
          setTimeout(function () {
            swal.close();
          }, 3000);

          this.access_token = data.access_token;
          localStorage.setItem("access_token", this.access_token);
          this.pedirPerfilPersonaRegistrada();
        },
        (error) => {
          swal.fire({
            title: "Se ha producido un error",
            text: error.error.message,
            icon: "error",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
          });
          setTimeout(function () {
            swal.close();
          }, 3000);
        }
      );
  }

  // Pedimos perfil de la persona registrada y asociada
  pedirPerfilPersonaRegistrada() {
    this.datosClientesService
      .cargarDataUsuario(this.access_token, this.profile_id)
      .subscribe(
        (data: any) => {
          this.dataUsuarioLogeado = data.data;
          this.role = data.data.role;

          // almacenamos en el local storage la data, el rol y le decimos que el token es "navegacion interna"
          // en admin un token con ese valor, lo que hara es entender que venimos de registro/login
          localStorage.setItem("role", this.role);
          localStorage.setItem(
            "dataUser",
            JSON.stringify(this.dataUsuarioLogeado)
          );
          localStorage.setItem("token", "navegacionInterna");
          // Una vez terminadas todas las llamadas, navegamos a "admin", y le decimos en el token que venimos de "navegacion interna", seccion login/registro
          this.router.navigate(["/admin"], {
            queryParams: { token: "navegacionInterna" },
          });
        },
        (error) => {
          //console.log(error);
        }
      );
  }

  // crear formulario recuperar contraseña
  crearFormularioRecuperarContrasena() {
    this.formRecuperarContrasena = this.fb.group({
      recuperarContrasena: ["", [Validators.required, Validators.email]],
    });
  }
  // limpiar formulario recuperar contraseña
  limpiarFormularioRecuperarContrasena() {
    this.formRecuperarContrasena.reset({
      recuperarContrasena: "",
    });
  }

  // errores mail
  get recuperarContrasenaNoValido() {
    return (
      this.formRecuperarContrasena.get("recuperarContrasena").invalid &&
      this.formRecuperarContrasena.get("recuperarContrasena").touched
    );
  }

  recuperarContrasena() {
    let recuperarContrasena = this.formRecuperarContrasena.value;

    let recuperarContrasenaEnviar = {
      forgot_email: recuperarContrasena.recuperarContrasena,
    };

    // this.loginRegistro = true;
    swal.showLoading();
    this.clearError();
    this.datosClientesService
      .recuperarContrasena(recuperarContrasenaEnviar)
      .subscribe(
        (data: any) => {
          swal.close();
          swal.fire({
            title: "<h5 style='color:#444444'>Éxito!</h5>",
            text: "Se le ha enviado un correo electrónico para que restaure su contraseña.",
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
        })
          setTimeout(function () {
            swal.close();
          }, 3000);

        },
        (error) => {
          swal.close();

          if (error.status == 422) {
            //this.recoveryPasswordError = error.error.errors;
            swal.fire({
              title: "Se ha producido un error",
              text: "El correo electrónico no es válido",
              icon: "error",
              iconColor: "#444444",
              confirmButtonColor: '#444444',
            });
            setTimeout(function () {
              swal.close();
            }, 3000);
          } else {
            swal.fire({
              icon: "error",
              title: error.error.message,
            });
            setTimeout(function () {
              swal.close();
            }, 3000);
          }
        }
      );
  }

  // We clear error
  clearError() {
    this.recoveryPasswordError = {};
  }

  // Mostramos el formulario de recuperar contraseña
  mostrarFormularioRecuperarContrasena() {
    if (this.mostrarRecuperarContrasena) {
      this.mostrarRecuperarContrasena = false;
    } else {
      this.mostrarRecuperarContrasena = true;
      this.limpiarFormularioRecuperarContrasena();
    }
  }

  // Mostramos el formulario de login
  mostrarFormularioLoginPublico() {
    if (this.mostrarLoginPublico) {
      this.mostrarLoginPublico = false;
    } else {
      this.mostrarLoginPublico = true;
      this.limpiarFormularioLoginPublico();
      this.clearError();
    }
  }

  ////////////////////////////
}
