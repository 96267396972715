import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'diaSemana'
})

export class DiaSemanaPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ){}

  diaSemana: string;
  
  
  transform(diaSsemanaIngles: any): any {
    
      // OBTENEMOS EL MES
      switch (diaSsemanaIngles) {
        case 'Monday':
          this.diaSemana = this.translateService.instant('MONDAY');
          break;
        case 'Tuesday':
          this.diaSemana = this.translateService.instant('THURSDAY');
          break;
        case 'Wednesday':
          this.diaSemana = this.translateService.instant('WEDNESDAY');
          break;
        case 'Thursday':
          this.diaSemana = this.translateService.instant('THURSDAY');
          break;
        case 'Friday':
          this.diaSemana = this.translateService.instant('FRIDAY');
          break;
        case 'Saturday':
          this.diaSemana = this.translateService.instant('SATURDAY');
          break;
        case 'Sunday':
          this.diaSemana = this.translateService.instant('SUNDAY');
          break;
      }
      return this.diaSemana;
  }

}