import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";


@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss']
})
export class PdfComponent implements OnInit {

  access_token: string; // almacena el access token
  profile_id: string; // almacena el profile id
  role: string; // rol de la persona autentificada
  token: string; // almacena el token recibido por URL


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private datosClientesService: DatosClienteServicioService

  ) { }

  ngOnInit() {

    this.profile_id = localStorage.getItem("profile_id");
    this.access_token = localStorage.getItem("access_token");

    if (this.token === "navegacionInterna") {
      this.access_token = localStorage.getItem("access_token");
    
    } else {
 
    }
  }

  

}
