import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AgendaCronologicaPublicaComponent } from './components/agenda-cronologica-publica/agenda-cronologica-publica.component';
import { MuroFirmasComponent } from './components/muro-firmas/muro-firmas.component';
import { AdminComponent } from './components/admin/admin.component';
import { FamiliaresAllegadosComponent } from './components/familiares-allegados/familiares-allegados.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { EditarProfileComponent } from './components/editar-profile/editar-profile.component';
import { SaveEditarProfileComponent } from './components/save-editar-profile/save-editar-profile.component';
import { LoginComponent } from './components/login/login.component';
import { VelasComponent } from './components/velas/velas.component';
import { TvComponent } from './components/tv/tv.component';
import { MasServiciosComponent } from './components/mas-servicios/mas-servicios.component';
import { CeremoniaOnlineComponent } from './components/ceremonia-online/ceremonia-online.component';
import { HomenajesPostumosComponent } from './components/homenajes-postumos/homenajes-postumos.component';
import { ContentDownloadComponent } from './components/content-download/content-download.component';
import { EncuestaComponent } from './components/encuesta/encuesta.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { TarjetaComponent } from './components/tarjeta/tarjeta.component';

const routes: Routes = [  
  { path: 'home/:web_code', component: HomeComponent },
  { path: 'tv/:web_code', component: TvComponent },
  { path: 'agendaCronologicaPublica', component: AgendaCronologicaPublicaComponent },
  { path: 'muroFirmas', component: MuroFirmasComponent },
  { path: 'velas', component: VelasComponent },
  //{ path: 'homenajesPostumos', component: HomenajesPostumosComponent },
  { path: 'masServicios', component: MasServiciosComponent },
  { path: 'ceremoniaOnline', component: CeremoniaOnlineComponent },
  //{ path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'familiaresAllegados', component: FamiliaresAllegadosComponent },
  { path: 'editarPerfil', component: EditarProfileComponent },
 // { path: 'guardarPerfil', component: SaveEditarProfileComponent },
  { path: 'invitation', component: InvitationComponent },
  { path: 'content-download', component: ContentDownloadComponent },
  { path: 'pdf', component: PdfComponent },
  { path: 'encuesta', component: EncuestaComponent },
  { path: 'tarjeta', component: TarjetaComponent },

  { path: '**', pathMatch: 'full', redirectTo: 'home/exit' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})





export class AppRoutingModule { }
