import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DatosClienteServicioService } from "../../servicios/datos-cliente-servicio.service";

@Component({
  selector: "app-mas-servicios",
  templateUrl: "./mas-servicios.component.html",
  styleUrls: ["./mas-servicios.component.scss"],
})
export class MasServiciosComponent implements OnInit {

  show_logo: string;
  web_code: string;

  floristeria_custom: { found: boolean, url?: string } = { found: false };
  floristeria_service: any;

  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  public servicesWOFlorist;

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;

  constructor(
    private datosClientesService: DatosClienteServicioService,
  ) {
    
  }

  ngOnInit() {

    this.show_logo = this.datosClientesService.show_logo();
    
    this.web_code = localStorage.getItem("web_code");

    this.datosClientesService.cargarPaginaPrincipal(this.web_code).subscribe(
      (data: any) => {

        let dataPaginaPrincipal = data.data;
        this.servicesWOFlorist = dataPaginaPrincipal.services.filter(item => item.category !== "florist");

        this.floristeria_custom = dataPaginaPrincipal.services.reduce((result, service) => {
          if (service.category === 'florist') {
            result.found = true;
            result.url = service.url;
          }
          return result;
        }, this.floristeria_custom);
    
      },
      (error) => {
        document.location.href = "http://celebrasuvida.es/";
      }
    );
  }


  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }

  // Objeto del OWL carrusel
  title = "";
  carouselOptions = {
    margin: 0,
    nav: false,
    navText: [],
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: false,
        loop: true,
        'smartSpeed': 0
      },
      600: {
        items: 2,
        nav: false,
        loop: true,
        'smartSpeed': 0
      },
      1000: {
        items: 3,
        nav: false,
        loop: true,
      },
      1500: {
        items: 3,
        nav: false,
        loop: true,
      },
    },
  };

  images = [
    {
      image: "/assets/img/slider1.jpg",
    },
    {
      image: "/assets/img/slider2.jpg",
    },
    {
      image: "/assets/img/slider3.jpg",
    },
    {
      image: "/assets/img/slider4.jpg",
    },
    {
      image: "/assets/img/slider5.jpg",
    },
    {
      image: "/assets/img/slider6.jpg",
    }
  ];
}
