import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'modificarTextos'
})

export class ModificarTextosPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ){}

  transform(texto: string): any {
    
    switch (texto) {
      case "admin":
        return this.translateService.instant('ADMINISTRATOR');
      case "family":
        return this.translateService.instant('FAMILY');
      case "close_friend":
        return this.translateService.instant('CLOSE_FRIEND');
      case "Resto_invitados":
        return this.translateService.instant('REST_OF_GUEST');
      case "Incineración":
        return this.translateService.instant('CREMATION');
      case "Inhumación":
        return this.translateService.instant('INHUMATION');
      case "Ceremonia":
        return this.translateService.instant('CEREMONY');
      case "Sepultura":
        return this.translateService.instant('GRAVE');
    }
  }

}