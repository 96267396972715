import {EventEmitter, Injectable,} from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { DatosClienteServicioService } from "../servicios/datos-cliente-servicio.service";


@Injectable({
    providedIn: 'root'
  })

export class HelperService {

  //flag para ejecutar recarga de tag HTML por cambio de idioma (solución para: si usa un doble pipe, no se traduce al instante)
  refreshLanguaje: boolean;

  access_token: string; // almacena el access token
  profile_id: string; // almacena el profile id

  comentariosPendientesModeracion: any;
  velasPendientesModeracion: any;
  elementosPendientesModeracion: number = 0;
  role: string;

   constructor(
    private translateService: TranslateService,
    private datosClientesService: DatosClienteServicioService
    ) {

    this.comentariosPendientesModeracion = localStorage.getItem('comentariosPendientesModeracion');
    this.velasPendientesModeracion = localStorage.getItem('velasPendientesModeracion');


    //función para comprobar si hemos cambiado de idioma, actualizamos flag con cambio. La variable la incluimos en IF de elemento HTML para refrescar su contenido.
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
        this.refreshLanguaje = true;
        setTimeout(() => {
            this.refreshLanguaje = false;
        }, 100);
    });
    }


  /*OTRAS FUNCIONES COMUNES*/


  //obtenemos datos sobre notificaciones
  updateQuantityNotificaciones() {

    let role = localStorage.getItem("role");

    if( role == "admin"){
      this.profile_id = localStorage.getItem("profile_id");
      this.access_token = localStorage.getItem("access_token");
      this.updateQuantityComentarios();
      this.updateQuantityVelas();
    }

  }

  // Obtenemos los comentarios
  updateQuantityComentarios() {
    // REFRESCAR COMENTARIOS
      if (this.profile_id && this.access_token) {

        this.datosClientesService
          .obtenemosComentariosParaValidar(this.profile_id, this.access_token)
          .subscribe(
            (data: any) => {
              this.comentariosPendientesModeracion = data.data.filter((obj) => obj.approved === 0).length;
              localStorage.setItem("comentariosPendientesModeracion", this.comentariosPendientesModeracion.toString());
              this.comentariosPendientesModeracion = localStorage.getItem('comentariosPendientesModeracion');
              this.obtenerElementosPendientesModeracion();
            },
            (error) => {}
          );
      }
  }

  // Obtenemos las velas
  updateQuantityVelas() {
    if (this.profile_id && this.access_token) {

      this.datosClientesService
        .obtenemosVelasValidar(this.profile_id, this.access_token)
        .subscribe(
          (data: any) => {
            this.velasPendientesModeracion = data.data.filter((obj) => obj.approved === 0).length;
            localStorage.setItem("velasPendientesModeracion", this.velasPendientesModeracion.toString());
            this.velasPendientesModeracion = localStorage.getItem('velasPendientesModeracion');
            this.obtenerElementosPendientesModeracion();
          },
          (error) => {}
        );
    }
  }

  // Obtenemos el total de elementos pendientes de moderación sumando las velas y los comentarios
  obtenerElementosPendientesModeracion() {
    this.elementosPendientesModeracion = 0;
    this.elementosPendientesModeracion = parseInt(this.comentariosPendientesModeracion) + parseInt(this.velasPendientesModeracion);
    localStorage.setItem(
      "elementosPendientesModeracion",
      this.elementosPendientesModeracion.toString()
    );
  }

}