import { Component, OnInit } from '@angular/core';
import { AgendaCronologicaPublica } from 'src/app/interfaces/interfaces';
import { DatosClienteServicioService } from 'src/app/servicios/datos-cliente-servicio.service';
import swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from "src/app/helpers/helper";
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment";

@Component({
  selector: 'app-agenda-cronologica-publica',
  templateUrl: './agenda-cronologica-publica.component.html',
  styleUrls: ['./agenda-cronologica-publica.component.scss']
})
export class AgendaCronologicaPublicaComponent implements OnInit {
  role: string;
  access_token: string;
  profile_id: string;
  //variable peliculas
  datosAgenda: any;
  mostrarError: boolean = false;
  mensajeError: string = '';
  tipoError: number;
  mostrarPagina: boolean;
  mostrarStreaming: boolean;
  hasVideo;
  asistentesEvento: any;
  tiposEvento: any;
  formEventAutenticado: FormGroup;
  ceremony_id: any;
  template: string;
  web_code: string; // parametro recibido por url, a través de este parametro podemos obtener el profifle id del cliente
  colapsar_formu:boolean;

  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(
    private datosClientesService: DatosClienteServicioService,
    private fb: FormBuilder,
    private helperService: HelperService,
    private translateService: TranslateService
    ) {

    // recogemos el idCliente del local Storage
    this.profile_id = localStorage.getItem('profile_id');
    // si existe el id que recibimos por url, llamamos al servicio
    if (this.profile_id) {
      // llamamos al servicio
      this.cargarAgendaCronologica(this.profile_id);
    } else {
      // si no existe elel local storage, redireccionamos y lo sacamos de la web
      document.location.href = 'http://celebrasuvida.es/';
    }

    if (localStorage.getItem('role')) {
      this.role = localStorage.getItem('role');
      //console.log("Rol encontrado ", this.role);
    }
    if (localStorage.getItem('access_token')) {
      this.access_token = localStorage.getItem('access_token');
      // console.log("Acces token encontrado ", this.access_token);
      this.cargarAgendaCronologicaAutenticado();
      this.obtenerTiposEventos();
    }

  }

  ngOnInit() {
    this.crearFormularioEventos();
    this.web_code = localStorage.getItem("web_code");
    this.obtenerTemplate(this.web_code);
  }

  // cargamos la agenda cronologica
  cargarAgendaCronologica(profile_id) {
    this.datosClientesService.cargarAgendaCronologica(profile_id).subscribe(
      (data: any) => {
        
        this.datosAgenda = data;
        
        // Formatear ISO formato fecha
        this.datosAgenda.data.forEach(function(part, index) {
          if (part.start) { 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');
          }

          if (part.end) {
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');   
          }
        });
       
        // console.log("NO AUTENTICADO", this.datosAgenda);
        this.mostrarPagina = true;
      },
      (error) => {
        // console.error(error);
        // console.log(error.error.message);
        // console.log(error.status);
        this.mostrarError = true;
        this.mensajeError = error.error.message;
        this.tipoError = error.status;
        this.mostrarPagina = false;
      }
    );
  }

  // cargamos la agenda cronologica
  cargarAgendaCronologicaAutenticado() {
    //console.log("servicio autebnticado" ,this.access_token);
    //console.log("servicio autebnticado" ,this.profile_id);

    this.datosClientesService.cargarAgendaCronologicaAutenticado(this.profile_id, this.access_token).subscribe(

      (data: any) => {
        this.datosAgenda = data;
         
        // Formatear ISO formato fecha
        this.datosAgenda.data.forEach(function(part, index) {
          if (part.start) { 
            let start = moment.utc(part.start);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              start = start.subtract(1, 'hours');
            }
            part.start = start.format('YYYY-MM-DD HH:mm:ss');
            //part.start = part.start.replace(' ', 'T');
          }

          if (part.end) {
            let end = moment.utc(part.end);
            if(part.office.office_code.includes("GC") || part.office.office_code.includes("TF")) {
              end = end.subtract(1, 'hours');
            }
            part.end = end.format('YYYY-MM-DD HH:mm:ss');
            //part.end = part.end.replace(' ', 'T');   
          }
        });
        
      },
      (error) => {
        // console.error(error);
        this.mostrarError = true;
        this.mensajeError = error.error.message;
        this.tipoError = error.status;
      }
    );
  }

  /** Mostramos el streaming
   * 
   * @param codigo 
   */
  videoStreaming(codigo: any) {
    //console.log("El código de reproducción es ", codigo);
    // enviar al final del stroll

    if (codigo) {
      // console.log("Si existe video");
      this.mostrarStreaming = false;
      this.hasVideo = codigo;

      if (!this.mostrarStreaming) {
        this.mostrarStreaming = true;
      }
      setTimeout(() => {
        //window.scrollTo(0, document.body.scrollHeight - 600);
        window.scrollTo({top: document.body.scrollHeight - 600, behavior: 'smooth'});
      }, 200)
    } else {
      // console.log("no existe video");
      this.mostrarStreaming = false;
      this.hasVideo = "";
    }

  }
  /**
   * Escondemos el streaming
   */
  noVideoStreaming() {
    this.mostrarStreaming = false;
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  /**
   * Confirmar asistencia
   */
  
  // confirmarAssitencia(ceremony_id: string) {
  //   // console.log("confirmar asistencia ", ceremony_id);
  //   swal.fire({
  //     title: this.translateService.instant('DO_YOU_WANT_CONFIRM_ATTENDANCE'),
  //     text: this.translateService.instant('IF_PRESS_YES_ATTENDANCE_TO_EVENT_CONFIRMED'),
  //     icon: 'warning',
  //     showCancelButton: true,
  //     iconColor: "#444444",
  //     confirmButtonColor: '#444444',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: this.translateService.instant('YES'),
  //     cancelButtonText: this.translateService.instant('NO'),
  //   }).then((result) => {

  //     if (result.isConfirmed) {
  //       //profile_id: string, access_token: string, ceremony_id: any

  //       let valores = {
  //         "assistance": true
  //       }

  //       this.datosClientesService.confirmarAsistenciaEvento(this.profile_id, this.access_token, ceremony_id, valores)
  //         .subscribe(
  //           (data: any) => {
  //             swal.fire({
  //               title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
  //               text: this.translateService.instant('YOUR_ATTENDANCE_HAS_BEEN_CONFIRMED'),
  //               icon: "success",
  //               iconColor: "#444444",
  //               confirmButtonColor: '#444444',
  //               confirmButtonText: 'OK',
  //             })
  //             setTimeout(() => {
  //               swal.close();
  //             }, 2000);
  //             this.cargarAgendaCronologicaAutenticado();
  //           },
  //           (error) => {
  //             //console.error(error);
  //             swal.fire(
  //               this.translateService.instant('ERROR'),
  //               this.translateService.instant('ERROR_OCCURRED'),
  //               'error'
  //             );
  //             setTimeout(() => {
  //               swal.close();
  //             }, 2000);
  //           }
  //         );
  //     }
  //   })
  // }


  /**
   * Obtener lista de asistentes
   */
  listaAsistentes(ceremony_id: string) {
    this.asistentesEvento = "";
    this.datosClientesService.obtenerAsistentesEventos(this.profile_id, this.access_token, ceremony_id)
      .subscribe(
        (data: any) => {
          this.asistentesEvento = data.data;
          //console.log(this.asistentesEvento);
        },
        (error) => {
          // console.error(error);
        }
      );
  }

  /**
   * Obtenemos los tipos de eventos
   */
  obtenerTiposEventos() {
    //this.tiposEvento = "";
    if (this.role === "admin") {
      this.datosClientesService.obtenerTiposEventos(this.profile_id, this.access_token)
        .subscribe(
          (data: any) => {
            this.tiposEvento = data.data;
            // console.log(this.tiposEvento);
          },
          (error) => {
            //console.error(error);
          }
        );
    }
  }

  /**
   * Formulario eventos
   */
  // Crea el formulario event crud
  crearFormularioEventos() {
    this.formEventAutenticado = this.fb.group({
      main: ['0', Validators.required],
      fechaInicio: ['', [Validators.required]],
      horaInicio: ['', [Validators.required]],
      fechaFin: ['', [Validators.required] ],
      horaFin: ['', [Validators.required]],
      venue: ['', [Validators.required]],
      address: ['', [Validators.required, Validators.minLength(6)]],
      room_name: [''],
      additional_info: [''],
      type_id: ['5', [Validators.required]],
      visible: ['public', [Validators.required]],

    });
  }

  // Limpia el formulario login
  limpiarFormularioEventos() {
    this.formEventAutenticado.reset({
      main: '0',
      fechaInicio: '',
      horaInicio: '',
      fechaFin: '',
      horaFin: '',
      venue:'',
      address: '',
      room_name: '',
      additional_info: '',
      type_id: '',
      visible: 'public',
    });
  }

  // Mostramos los errores
  get fechaInicioNoValido() {
    return this.formEventAutenticado.get('fechaInicio').invalid && this.formEventAutenticado.get('fechaInicio').touched
  }
  get horaInicioNoValido() {
    return this.formEventAutenticado.get('horaInicio').invalid && this.formEventAutenticado.get('horaInicio').touched
  }
   get fechaFinNoValido() {
    return this.formEventAutenticado.get('fechaFin').invalid && this.formEventAutenticado.get('fechaFin').touched
  }
  get horaFinNoValido() {
    return this.formEventAutenticado.get('horaFin').invalid && this.formEventAutenticado.get('horaFin').touched
  }
  get addresNoValido() {
    return this.formEventAutenticado.get('address').invalid && this.formEventAutenticado.get('address').touched
  }
  //get roomNameNoValido() {
  //  return this.formEventAutenticado.get('room_name').invalid && this.formEventAutenticado.get('room_name').touched
  //}

  // Comporbamos si el formulario es correcto
  formularioEvento() {
    // comprobamos que el formulario se ha rellenado correctamente
    if (this.formEventAutenticado.invalid) {
      // validaciones
      return Object.values(this.formEventAutenticado.controls).forEach(control => {

        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => control.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });

    } else {
      //console.log("Formulario correcto");
      this.llamadaCrearEvento();
    }
  }

  collapse() {
    this.colapsar_formu=!this.colapsar_formu;
    console.log(this.colapsar_formu);
    
  }



  /**
   * Llamamos a al api a crear el evento
   */
  llamadaCrearEvento() {
    let valoresFormularioCrearEvento = this.formEventAutenticado.value;

    // let fecha_y_hora_inicio = valoresFormularioCrearEvento.fechaInicio + " " + valoresFormularioCrearEvento.horaInicio + ":00";
    // let fecha_y_hora_fin = valoresFormularioCrearEvento.fechaFin + " " + valoresFormularioCrearEvento.horaFin + ":00";

    let fecha_y_hora_inicio;
    let fecha_y_hora_fin;

    // Comporbamos si la hora tiene 5 u 8
    if (valoresFormularioCrearEvento.horaInicio.length === 5) {
      fecha_y_hora_inicio = valoresFormularioCrearEvento.fechaInicio + " " + valoresFormularioCrearEvento.horaInicio + ":00";
    } else {
      fecha_y_hora_inicio = valoresFormularioCrearEvento.fechaInicio + " " + valoresFormularioCrearEvento.horaInicio;
    }
    if (valoresFormularioCrearEvento.horaFin.length === 5) {
      fecha_y_hora_fin = valoresFormularioCrearEvento.fechaFin + " " + valoresFormularioCrearEvento.horaFin + ":00";
    } else {
      fecha_y_hora_fin = valoresFormularioCrearEvento.fechaFin + " " + valoresFormularioCrearEvento.horaFin;
    }

    let valoresFormularioCrearEventoEnviar = {
      "main": parseInt(valoresFormularioCrearEvento.main),
      "start": fecha_y_hora_inicio,
      "end": fecha_y_hora_fin,
      "venue": valoresFormularioCrearEvento.venue,
      "address": valoresFormularioCrearEvento.address,
      "room_name": valoresFormularioCrearEvento.room_name,
      "additional_info": valoresFormularioCrearEvento.additional_info,
      "type_id": valoresFormularioCrearEvento.type_id,
      "visible": valoresFormularioCrearEvento.visible
    }
    //console.log(valoresFormularioCrearEventoEnviar);

    if (this.ceremony_id) {
      // Editamos evento

      //console.log("EDITAMOS EVENTO ", this.ceremony_id);
      // Creamos evento
      this.datosClientesService.editamosEvento(this.profile_id, this.access_token, valoresFormularioCrearEventoEnviar, this.ceremony_id)
        .subscribe(
          (data: any) => {
            swal.fire({
              title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
              text: this.translateService.instant('EVENT_EDITED_SUCCESSFULLY'),
              icon: "success",
              iconColor: "#444444",
              confirmButtonColor: '#444444',
              confirmButtonText: 'OK',
            })
            setTimeout(() => {
              //location.reload();
              swal.close();
            }, 2000);
            this.ceremony_id = '';
            this.limpiarFormularioEventos()
            this.cargarAgendaCronologicaAutenticado();
          },
          (error) => {
            //console.error(error.error.errors.main);
            this.ceremony_id = '';
            swal.fire(
              'Error!',
              error.error.errors.main[0],
              'error'
            )
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        );
    } else {
      // Creamos evento
      this.datosClientesService.creamosEvento(this.profile_id, this.access_token, valoresFormularioCrearEventoEnviar)
        .subscribe(
          (data: any) => {
            swal.fire({
              title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
              text: this.translateService.instant('EVENT_CREATED_SUCCESSFULLY'),
              icon: "success",
              iconColor: "#444444",
              confirmButtonColor: '#444444',
              confirmButtonText: 'OK',
            })
            setTimeout(() => {
              // location.reload();
              swal.close();
            }, 2000);
            this.limpiarFormularioEventos()
            this.ceremony_id = '';
            this.cargarAgendaCronologicaAutenticado();
          },
          (error) => {
            console.error(error);
            this.ceremony_id = ''
            swal.fire(
              this.translateService.instant('ERROR'),
              this.translateService.instant('ERROR_OCCURRED'),
              'error'
            )
            setTimeout(() => {
              swal.close();
            }, 2000);
          }
        );
    }
  }

  /*
  * Borrar evento
   */
  borrarEvento(ceremony_id: any) {
    //console.log(ceremony_id);

    swal.fire({
      title: this.translateService.instant('DO_YOU_WANT_DELETE_EVENT'),
      text: this.translateService.instant('IF_PRESS_YES_EVENT_WILL_BE_DELETED'),
      icon: 'warning',
      showCancelButton: true,
      iconColor: "#444444",
      confirmButtonColor: '#444444',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translateService.instant('YES'),
      cancelButtonText: this.translateService.instant('NO'),
    }).then((result) => {

      if (result.isConfirmed) {

        this.datosClientesService.borrarEvento(this.profile_id, this.access_token, ceremony_id)
          .subscribe(
            (data: any) => {
              swal.fire({
                title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
                text: this.translateService.instant('EVENT_DELETED_SUCCESSFULLY'),
                icon: "success",
                iconColor: "#444444",
                confirmButtonColor: '#444444',
                confirmButtonText: 'OK',
              })
              setTimeout(() => {
                swal.close();
              }, 2000);
              this.cargarAgendaCronologicaAutenticado();
            },
            (error) => {
              console.error(error);
              swal.fire(
                this.translateService.instant('ERROR'),
                this.translateService.instant('ERROR_OCCURRED'),
                'error'
              )
              setTimeout(() => {
                swal.close();
              }, 2000);
            }
          );
      }
    })
  }

  editarEvento(evento: any) {
    //console.log(evento);

    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight - 800);
    }, 500)
    this.colapsar_formu=!this.colapsar_formu;
    this.ceremony_id = evento.id;
    //console.log("Event id ", this.ceremony_id);

    this.formEventAutenticado.patchValue({
      main: evento.main.toString(),
      fechaInicio: evento.start.split(" ")[0],
      horaInicio: evento.start.split(" ")[1],
      fechaFin: evento.end.split(" ")[0],
      horaFin: evento.end.split(" ")[1],
      venue: evento.venue,
      address: evento.address,
      room_name: evento.room_name,
      additional_info: evento.additional_info,
      type_id: evento.type.id,
      visible: evento.visible,
    });
  }

  
  /**
   * Llamameos al servicio para poder averiguar el template que tenemos
   * @param web_code 
   */
   obtenerTemplate(web_code: string) {
    this.datosClientesService.cargarPaginaPrincipal(web_code).subscribe(
      (data: any) => {
   
        localStorage.setItem("template", "template1");
        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute("href", "assets/css/template1.css");
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  //////////////
}
