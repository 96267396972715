import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-homenajes-postumos",
  templateUrl: "./homenajes-postumos.component.html",
  styleUrls: ["./homenajes-postumos.component.scss"],
})
export class HomenajesPostumosComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  navegarVerInstalaciones() {
    window.open("https://tanatorio.albia.es", "_blank");
  }

  // Objeto del OWL carrusel
  title = "";
  carouselOptions = {
    margin: 0,
    nav: true,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsiveClass: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
        loop: true,
        'smartSpeed': 0
      },
      600: {
        items: 2,
        nav: true,
        loop: true,
        'smartSpeed': 0
      },
      1000: {
        items: 3,
        nav: true,
        loop: true,
      },
      1500: {
        items: 3,
        nav: true,
        loop: true,
      },
    },
  };

  images = [
    {
      image: "/assets/img/slider1.jpg",
    },
    {
      image: "/assets/img/slider2.jpg",
    },
    {
      image: "/assets/img/slider3.jpg",
    },
    {
      image: "/assets/img/slider4.jpg",
    },
    {
      image: "/assets/img/slider5.jpg",
    },
    {
      image: "/assets/img/slider6.jpg",
    }
  ];
}
