import { Component, DoCheck, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, DoCheck {

  mostrarFooter: boolean;
  tv: string; // almacena la variable donde comprobaremos si lo estamos visualizando en una tv
  show_logo: string;

  color = "#0F5D75";

  constructor() {
    this.tv = localStorage.getItem('tv');
   }

  ngOnInit() {
    let rutaCss: any = document.getElementById("templates-albia");
    rutaCss.setAttribute(
      "href",
      "assets/css/template5.css"
    );
    this.show_logo = localStorage.getItem('logo');
    console.log("logo", this.show_logo);
    
  }

  ngDoCheck() {
    
    // recogemos el valor del local storage
    this.tv = localStorage.getItem('tv');
    // console.log("el valor es ", this.tv);

    // si es true, escondemos el footer por que estamos en una tv
    if (this.tv === "tv") {
      this.mostrarFooter = false;
    } else {
      this.mostrarFooter = true;
    }
    
  }

    /*
  RED DE TANATORIOS ALBIA
  */
  redTanatorios() {
    window.open('https://tanatorio.albia.es/', '_blank');
  }


  /*
  CELEBRA SU VIDA
  */
  webCelebraSuVida() {
    window.open('https://celebrasuvida.es/', '_blank');
  }  



}
