import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatosClienteServicioService } from "src/app/servicios/datos-cliente-servicio.service";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { HelperService } from "src/app/helpers/helper";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})

export class AdminComponent implements OnInit {
  dataAdmin: any; // aqui almacenamos la data despues de comprobar que su token recibido por URL es valido
  dataAdministrador: any; // aqui almacenamos la data del administrador, despues de comporbar que su acces token es valido
  token: string; // almacena el token recibido por URL
  access_token: string; // almacena el access token
  profile_id: string; // almacena el profile id
  role: string; // rol de la persona autentificada
  mostrarPanelAdministracion: boolean;
  mostrarPagina: boolean;
  // datos del usuario autenticado
  nombre: string;
  apellidos: string;
  dni: string;
  correoElectronico: string;
  telefono: string;
  web_code: string;
  nombreDifunto: String;
  apellidosDifunto: String;
  fnacimientoDifunto: string;
  fdefuncionDifunto: string;
  title_epitaphDifunto: string;
  message_epitaphDifunto: string;
  copiarEnlaceHome: string;
  template: any;
  templateSelected: any;
  mostrarBotonGuardarPlantilla: boolean = false;
  comentariosPendientesModeracion:any;
  velasPendientesModeracion:any;
  advancedProfile: string;
  show_logo: string;
  
  color = "#0F5D75";
  bg = "url(/assets/bg/bg1.png)";

  constructor(
    private route: ActivatedRoute,
    private datosClientesService: DatosClienteServicioService,
    private router: Router,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {

    // //actualizamos notificaciones y obtenemos dato de localstorage
    this.helperService.updateQuantityNotificaciones();
    this.comentariosPendientesModeracion = parseInt(localStorage.getItem('comentariosPendientesModeracion'));
    this.velasPendientesModeracion = parseInt(localStorage.getItem('velasPendientesModeracion'));

    this.profile_id = localStorage.getItem("profile_id");
    this.access_token = localStorage.getItem("access_token");

    localStorage.setItem("tv", "");

    // recogemos el parametro
    this.route.queryParams.subscribe((params) => {

      this.token = params["token"];
      localStorage.setItem("token", this.token);

      if (this.token === "navegacionInterna") {
        this.access_token = localStorage.getItem("access_token");
        this.obtenerDatosAdministrador();
      } else {
        this.llamadaObtenerTokenAccess(this.token);
      }


      if(this.token !== "navegacionInterna"){
        this.profile_id = params["profile_id"];
        this.web_code = params["profile_web_code"];
        this.access_token = params["access_token"];
  
        localStorage.setItem("profile_id", this.profile_id);
        localStorage.setItem("web_code", this.web_code);
        localStorage.setItem("access_token", this.access_token);
      }


    });


    // LOGIN GOOGLE
    if(localStorage.getItem("access_token")){

      localStorage.setItem("token", "navegacionInterna");
      this.obtenerDatosAdministrador();
    }

    // Navegacion interna --> Venimos de registranos o logearnos
    // el resto --> entramos con un token via url
    if (this.token === "navegacionInterna") {
      this.access_token = localStorage.getItem("access_token");
      this.obtenerDatosAdministrador();
    } else {
      if(!localStorage.getItem("access_token")){
        this.llamadaObtenerTokenAccess(this.token);
      }
    }

    //función para devolver a return URL si está definida
    if(localStorage.getItem("returnURL")){
      let returnURL = localStorage.getItem("returnURL");
      localStorage.removeItem("returnURL");
      this.router.navigateByUrl("/" + returnURL);
    }

  }


  // en esta llamada enviamos el token recibido por URL, y obtenemos el token access
  llamadaObtenerTokenAccess(token: string) {
    console.log(token);
    // Obtenemos el profile_id y el acces_token asociados
    this.datosClientesService.obtenerTokenAcces(token).subscribe(
      (data: any) => {
        //console.log("web code obtenerTokenAcces", data);
        this.dataAdmin = data;
        this.profile_id = data.profile_id;
        this.access_token = data.access_token;
        
        
        localStorage.setItem("profile_id", this.profile_id);
        localStorage.setItem("access_token", this.access_token);
       
        this.obtenerDatosAdministrador();
        //console.log("datos", data);
        this.mostrarPanelAdministracion = true;
      },
      (error) => {
        //console.error(error);
        // si nos da un error de autentificacion lo enviamos fuera
        this.mostrarPanelAdministracion = false;
        //document.location.href = "celebrasuvida.es";
      }
    );
  }

  // Tras tener el access token, obtenemos los datos del administrador
  obtenerDatosAdministrador() {
    this.profile_id = localStorage.getItem("profile_id");
    this.access_token = localStorage.getItem("access_token");
    // console.log("llamada servicio con estos parametros" + this.access_token, this.profile_id);
    this.datosClientesService
      .cargarDataAdministrador(this.access_token, this.profile_id)
      .subscribe(
        (data: any) => {
          console.log("web code cargarDataAdministrador", data);
          this.dataAdministrador = data;
          // console.log(data);
          this.nombre = data.data.name;
          this.apellidos = data.data.lastname;
          this.dni = data.data.dni;
          this.correoElectronico = data.data.email;
          this.telefono = data.data.phone;
          this.role = data.data.role;
          localStorage.setItem("role", this.role);
          this.web_code = data.data.web_code;
          localStorage.setItem("web_code", this.web_code);
          this.cargarDataProfile();
          
        },
        (error) => {
          console.error(error);
          this.mostrarPanelAdministracion = false;
          //document.location.href = "celebrasuvida.es";
        }
      );
  }
  /**
   *  Cragamos el perfil del fallecido
   */

  cargarDataProfile() {
    // pedimos los datos
    this.datosClientesService.cargarPaginaPrincipal(this.web_code).subscribe(
      (data: any) => {
        // console.log(data);
        this.nombreDifunto = data.data.name;
        this.apellidosDifunto = data.data.last_name;
        this.fnacimientoDifunto = data.data.birthday;
        this.fdefuncionDifunto = data.data.death;
        this.title_epitaphDifunto = data.data.title_epitaph;
        this.message_epitaphDifunto = data.data.message_epitaph;
        this.mostrarPagina = true;
        this.mostrarPanelAdministracion = true;
        this.template = "template1";
        this.advancedProfile = data.data.is_advance;
         

        //set advancedProfile
        localStorage.setItem("advancedProfile", this.advancedProfile);
        
        // Metemos el template en el set item
        localStorage.setItem("template", "template1");

 

        // Comprobamos que plantilla hay seleccionada y cargamos el css de dicha plantilla
        let rutaCss: any = document.getElementById("templates-albia");
        rutaCss.setAttribute(
          "href",
          "assets/css/template1.css"
        );
        // console.log("Template agenda: ", this.template);
        //this.template = '2';
        //console.log(this.template);
        // this.obtenerWebCode();
      },
      (error) => {
        // si profile_id da error, es que no son correctos y redirigimos fuera de la app
        //document.location.href = "celebrasuvida.es";
        console.error(error);
      }
    );
  }

  /**
   * Visitamos la zina publica
   */
  visitarZonaPublica() {
    //console.log("visitar");
    this.router.navigate(["home", this.web_code]);
  }

  /**
   * Copiamos el enlace
   */
  
  
  copiarEnlace() {

    this.copiarEnlaceHome = "https://csv-demo.informatica-integral.com/invitation?profile=" + this.web_code;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copiarEnlaceHome;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  
    

    swal.fire({
      title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
      text: this.translateService.instant('LINK_COPIED_SUCCESSFULLY'),
      icon: "success",
      iconColor: "#444444",
      confirmButtonColor: '#444444',
      confirmButtonText: 'OK',
    });
    //  console.log(data);
    setTimeout(function () {
      swal.close();
    }, 3000);
  }

  /**
   *  Gestionamos el selected
   * @param template
   */
  
  templateSeleccionado(template?: string) {
    // Le quitamos a las plantillas la clase selected
    var element1 = document.getElementById("imagen1");
    element1.classList.remove("seleccionado");

    var element2 = document.getElementById("imagen2");
    element2.classList.remove("seleccionado");

    var element3 = document.getElementById("imagen3");
    element3.classList.remove("seleccionado");

    var element4 = document.getElementById("imagen4");
    element4.classList.remove("seleccionado");

    // añadimos el selected
    var elementoSeleccionado = document.getElementById(template);
    elementoSeleccionado.classList.add("seleccionado");

    switch (template) {
      case "imagen1":
        this.templateSelected = 1;
        break;
      case "imagen2":
        this.templateSelected = 2;
        break;
      case "imagen3":
        this.templateSelected = 3;
        break;
      case "imagen4":
        this.templateSelected = 4;
        break;
    }
    if (this.templateSelected) {
      this.mostrarBotonGuardarPlantilla = true;
    }
    // console.log("Plantilla seleccionada, ", this.templateSelected);
  }

  /**
   * Cuando cancelamos la seleccion del template, quitamos el selected
   */

  deseleccionarTemplate() {
    // Le quitamos a las plantillas la clase selected
    var element1 = document.getElementById("imagen1");
    element1.classList.remove("seleccionado");

    var element2 = document.getElementById("imagen2");
    element2.classList.remove("seleccionado");

    var element3 = document.getElementById("imagen3");
    element3.classList.remove("seleccionado");

    var element4 = document.getElementById("imagen4");
    element4.classList.remove("seleccionado");
  }

  /**
   * Navegamos a editar web
   */

  navegarEditarWeb() {
    this.router.navigate(["editarPerfil"]);
  }

  /**
   * Enviamos el template seleccionado
   */

  enviarTemplateSeleccionado() {
    let template = this.templateSelected.toString();
    let valores: any = {
      name: this.nombreDifunto,
      lastname: this.apellidosDifunto,
      birthday: this.fnacimientoDifunto,
      death: this.fdefuncionDifunto,
      title_epitaph: this.title_epitaphDifunto,
      message_epitaph: this.message_epitaphDifunto,
      template: template,
    };
    //console.log(valores);

    this.datosClientesService
      .modificarProfile(valores, this.profile_id, this.access_token)
      .subscribe(
        (data: any) => {
          // console.log(data);
          // Mensaje de alerta de que la plantilla ha sido seleccionada correctamente
          swal.fire({
            title: "<h5 style='color:#444444'>" + this.translateService.instant('SUCCESS') + "</h5>",
            text: this.translateService.instant('TEMPLATE_SELECTED_SUCCESSFULLY'),
            icon: "success",
            iconColor: "#444444",
            confirmButtonColor: '#444444',
            confirmButtonText: 'OK',
          });
          // Recargamos la informacion del fallecido
          this.cargarDataProfile();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  /////
}