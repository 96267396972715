import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// importamos las interfaces para los datos
import { PaginaPrincipalPublica, AgendaCronologicaPublica, MuroFirma } from '../interfaces/interfaces'
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})

export class DatosClienteServicioService {
  public url: string;
  public logo: string;

  constructor(private http: HttpClient) {
    this.url = environment.api;
  }

  cargarAgendaCronologica(profile_id) {
    // console.log(profile_id);
    return this.http.get(`${this.url}/public/profile/${profile_id}/ceremonies`);
  }

  // Cargamos agenda autenticado
  cargarAgendaCronologicaAutenticado(profile_id: any, access_token: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // console.log("acces token ", bear_access_token);
    // console.log("profile id: ", profile_id);
    // hacemos la peticion
    return this.http.get<any>(`${this.url}/profile/${profile_id}/ceremonies`, { headers: header });
  }

  cargarMuroFirmasSinAutentificacion(profile_id: string) {
    // return this.http.get<MuroFirma>("../assets/data/muroFirmas.json");
    return this.http.get<any>(`${this.url}/public/profile/${profile_id}/comments`);
  }

  cargarMuroFirmasSinAutentificacionPaginate(profile_id: string, params: any) {
    // return this.http.get<MuroFirma>("../assets/data/muroFirmas.json");
    return this.http.get<any>(`${this.url}/public/profile/${profile_id}/comments/paginate`, { params });
  }

  cargarMuroFirmasConAutentificacion(access_token: string, profile_id: string) {
    //profile/{profile_id}/comments

    // fabricamos la cabecera de la peticion
    //console.log(profile_id);
    //console.log(access_token);
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.get<any>(`${this.url}/profile/${profile_id}/comments/private`, { headers: header });
  }

  cargarMuroFirmasConAutentificacionPaginado(access_token: string, profile_id: string, params: any) {

    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token,
    })
    // hacemos la peticion
    return this.http.get<any>(`${this.url}/profile/${profile_id}/comments/private/paginate?page=${params.page}`, { headers: header });
  }

  cargarImagenVacia() {
    return this.http.get<MuroFirma>("../assets/data/imagenBlanco.json");
  }

  cargarPaginaPrincipal(profile_id) {
    //console.log(profile_id);
    return this.http.get<PaginaPrincipalPublica>(`${this.url}/public/profile/${profile_id}`);
  }

  // en esta llamada enviamos el token recibido por URL, y obtenemos el token access
  obtenerTokenAcces(token: string) {
    console.log(`${this.url}/auth/login/declarant?token=${token}`);
    return this.http.get<any>(`${this.url}/auth/login/declarant?token=${token}`);
  }

  // Enviamos el token access, y obtenemos los datos del administrador
  cargarDataAdministrador(access_token, profile_id) {
    // fabricamos la cabecera de la peticion
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.get(`${this.url}/auth/user/${profile_id}`, { headers: header });
  }

  // Esta función enviara una llamada POST a la api, para generar la invitación
  enviarInvitacionFamiliaresAllegados(tipoInvitacion: any) {
    // console.log(valores);
    // fabricamos la cabecera de la peticion
    let profile_id = localStorage.getItem('profile_id');
    let access_token = localStorage.getItem('access_token');
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la petición
    return this.http.post(`${this.url}/profile/${profile_id}/invitations/store`, tipoInvitacion, { headers: header });
  }

  // Esta función enviara una llamada POST a la api, para invitar a familiares y allegados a registrarse
  registrarse(valoresFormulario: any) {
    //console.log(valoresFormulario);
    // fabricamos la cabecera de la peticion
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    })
    // hacemos la petición
    //${this.url}
    //auth/register
    return this.http.post(`${this.url}/auth/register`, valoresFormulario, { headers: header });

  }


  // Esta función enviara una llamada POST a la api, para logearnos
  login(valoresFormulario: any) {
    //console.log(valoresFormulario);
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    })
    // hacemos la petición
    //${this.url}
    //auth/register
    return this.http.post(`${this.url}/auth/login`, valoresFormulario, { headers: header });

  }

  // asociamos la persona registrada
  asociarPersonaRegistrada(access_token: string, profile_id: string) {

    let parametro = {
      "profile_id": profile_id,
    }
    // fabricamos la cabecera de la peticion
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.post(`${this.url}/auth/profile/join`, parametro, { headers: header });
  }


  // Enviamos el token access, y obtenemos los datos del administrador
  cargarDataUsuario(access_token, profile_id) {
    // fabricamos la cabecera de la peticion

    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.get(`${this.url}/auth/user/${profile_id}`, { headers: header });
  }


    // Enviamos el token access, y obtenemos los datos del administrador
    cargarProfilesUsuario(access_token) {
      // fabricamos la cabecera de la peticion
  
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
        'Authorization': bear_access_token
      })
      // hacemos la peticion
      return this.http.get(`${this.url}/auth/user/profiles`, { headers: header });
    }


  // Enviamos el token access, y obtenemos los datos del administrador
  cargarListaFamiliaresAllegados(access_token, profile_id) {
    // fabricamos la cabecera de la peticion
    //console.log(profile_id);
    //console.log(access_token);
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.get(`${this.url}/profile/${profile_id}/clients`, { headers: header });
  }



  // LLAMADA QUE ACTUALIZA EL PROFILE DEL CLIENTE
  modificarProfile(valores: any, profile_id: string, access_token: string) {
    // fabricamos la cabecera de la peticion

    //console.log(valores);
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      // 'content-type': 'multipart/form-data',
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.post(`${this.url}/profile/${profile_id}/update?`, valores, { headers: header });
  }


  // Esta función enviara una llamada POST a la api, para poder recuperar contrasena
  recuperarContrasena(valoresFormulario: any) {
    //console.log(valoresFormulario);
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    })
    return this.http.post(`${this.url}/auth/forget-password/send`, valoresFormulario, { headers: header });
  }

  // Esta función enviara una llamada POST a la api, para poder enviar comentarios publicos
  insertarComentarioPublico(valoresFormulario: any, profile_id) {
    //console.log(valoresFormulario);
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    })
    return this.http.post(`${this.url}/public/profile/${profile_id}/comments/store`, valoresFormulario, { headers: header });
  }

  // Esta función enviara una llamada POST a la api, para poder enviar comentarios publicos
  insertarComentarioAutenticado(valoresFormulario: any, profile_id: string, access_token: string, options: any) {
    //console.log(valoresFormulario);
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.post(`${this.url}/profile/${profile_id}/comments/store`, valoresFormulario, { headers: header });
  }

  // Borramos el comentario
  borrarComentarioAutenticado(profile_id: string, access_token: string, comment_id: number) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.delete(`${this.url}/profile/${profile_id}/comments/${comment_id}/destroy`, { headers: header });
  }
  // Editamos el comentario 
  editarComentarioAutenticado(profile_id: string, access_token: string, comment_id: number, formDataUpdated: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.post(`${this.url}/profile/${profile_id}/comments/${comment_id}/update`, formDataUpdated, { headers: header });
  }

  // Obtenemos los comentarios para validarlos
  obtenemosComentariosParaValidar(profile_id: string, access_token: string) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    // hacemos la peticion
    return this.http.get(`${this.url}/profile/${profile_id}/comments`, { headers: header });
  }

  // Aprobamos el comentario
  // Borramos el comentario
  aprobarComentarioAutenticado(profile_id: string, access_token: string, comment_id: number) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })

    //console.log(header);
    return this.http.put(`${this.url}/profile/${profile_id}/comments/${comment_id}/approve`, {}, { headers: header });
  }

  // Borramos el comentario
  borrarInvitadoAutenticado(profile_id: string, access_token: string, client_id: number) {
    //console.log(invitation_id);
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.delete(`${this.url}/profile/${profile_id}/clients/${client_id}/detach`, { headers: header });

  }


    // Actualizar rol invitados
    asignarRolInvitado(profile_id: string, access_token: string, client_id: number, role_change: string) {
      //console.log(invitation_id);
      let params = { "role": role_change};
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'x-requested-with': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': bear_access_token
      })
      return this.http.post(`${this.url}/profile/${profile_id}/clients/${client_id}/update_role`, params, { headers: header });
  
    }

  // Encender Vela Publico
  encenderVelaPublico(valoresFormulario: any, profile_id: any) {

    let roleEncenderVelas = localStorage.getItem("role");
    let bearAccesstokenEncenderVelas = localStorage.getItem("access_token");

    if(!roleEncenderVelas){

        let header = new HttpHeaders({
          'Content-Type': 'application/json',
          'x-requested-with': 'XMLHttpRequest',
        })

        return this.http.post(`${this.url}/public/profile/${profile_id}/candles/store`, valoresFormulario, { headers: header });

    } else {

      let bear_access_token = "Bearer " + bearAccesstokenEncenderVelas;
      let header = new HttpHeaders({
        'x-requested-with': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': bear_access_token
      })

      return this.http.post(`${this.url}/profile/${profile_id}/candles/store`, valoresFormulario, { headers: header });

    }
  }

  // Funcion que carga las velas
  cargarVelas(profile_id: any) {
    return this.http.get(`${this.url}/public/profile/${profile_id}/candles`);
  }

  // Funcion que carga las velas paginadas
  cargarVelasPaginadas(profile_id: any, params: any) {
    return this.http.get(`${this.url}/public/profile/${profile_id}/candles/paginate`, { params });
  }

  // Borramos una vela
  borrarVelas(profile_id: string, access_token: string, candle_id: number) {

    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
   return this.http.delete(`${this.url}/profile/${profile_id}/candles/${candle_id}/destroy`, { headers: header });

    
  }

  /*
    borrarComentarioAutenticado(profile_id: string, access_token: string, comment_id: number) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.delete(`${this.url}/profile/${profile_id}/comments/${comment_id}/destroy`, { headers: header });
  }
  */


  // Borramos el comentario
  confirmarAsistenciaEvento(profile_id: string, access_token: string, ceremony_id: any, valores: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.put(`${this.url}/profile/${profile_id}/ceremonies/${ceremony_id}/assistance/update`, valores, { headers: header });
  }

   // Obtenemos los comentarios para validarlos
   
   obtenerAsistentesEventos(profile_id: string, access_token: string, ceremony_id: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    return this.http.get(`${this.url}/profile/${profile_id}/ceremonies/${ceremony_id}/assistance`, { headers: header });
  }

    //Obtenemos los tipos de eventos
    obtenerTiposEventos(profile_id: any, access_token: any) {
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
        'Authorization': bear_access_token
      })
      // console.log("acces token ", bear_access_token);
      // console.log("profile id: ", profile_id);
      // hacemos la peticion
      return this.http.get<any>(`${this.url}/profile/${profile_id}/ceremonies/ceremony-types`, { headers: header });
    } 

  // Creamos el evento 
  creamosEvento(profile_id: string, access_token: string, data: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.post(`${this.url}/profile/${profile_id}/ceremonies/store`, data, { headers: header });
  } 

    // Borramos un evento
    borrarEvento(profile_id: string, access_token: string, ceremony_id: number) {
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'x-requested-with': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': bear_access_token
      })
      return this.http.delete(`${this.url}/profile/${profile_id}/ceremonies/${ceremony_id}/destroy`, { headers: header });
    } 
  
    
  // Creamos el evento 
  editamosEvento(profile_id: string, access_token: string, data: any,  ceremony_id: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.put(`${this.url}/profile/${profile_id}/ceremonies/${ceremony_id}/update`, data, { headers: header });
  }  // /profile/{profile_id}/ceremonies/{ceremony_id}/update


    // Obtenemos todas las invitaciones
    obtenerInvitaciones() {
      let profile_id = localStorage.getItem('profile_id');
      let access_token = localStorage.getItem('access_token');
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-requested-with': 'XMLHttpRequest',
        'Authorization': bear_access_token
      })
      // hacemos la petición
      return this.http.get(`${this.url}/profile/${profile_id}/invitations`, { headers: header });
    } ///profile/{profile_id}/invitations


  // Refrescamos la invitacion
  refrescamosInvitacion(profile_id: string, access_token: string, invitation_id: any) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.put(`${this.url}/profile/${profile_id}/invitations/${invitation_id}/update/token`, {} , { headers: header });
  }  ///profile/{profile_id}/invitations/{invitation_id}/update/token


  // Obtenemos los comentarios para validarlos
  obtenemosVelasValidar(profile_id: string, access_token: string) {
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
      'Authorization': bear_access_token
    })
    return this.http.get(`${this.url}/profile/${profile_id}/candles`, { headers: header });
  }

  // Validamos las velas no validadas
  aprobarVelaAutenticado(profile_id: string, access_token: string, candle_id: number) {
    
    let bear_access_token = "Bearer " + access_token;
    let header = new HttpHeaders({
      'x-requested-with': 'XMLHttpRequest',
      'Accept': 'application/json',
      'Authorization': bear_access_token
    })
    return this.http.post(`${this.url}/profile/${profile_id}/candles/${candle_id}/update`, {}, { headers: header });

  }

    // Enviar encuesta
    enviarEncuesta(profile_id: string, access_token: string, data: any) {
      let bear_access_token = "Bearer " + access_token;
      let header = new HttpHeaders({
        'x-requested-with': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': bear_access_token
      })
      return this.http.post(`${this.url}/profile/${profile_id}/survey/store`, data, { headers: header });
    } 

    show_logo () {
      this.logo = localStorage.getItem('logo');
      console.log("logo", this.show_logo);
      return this.logo;
    }




  ////////
}
